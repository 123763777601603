import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {Subscription} from 'rxjs';
import {CommentService} from '../../../shared/services/comment.service';
import {Video} from '../../../shared/interfaces/video';
import {UserService} from '../../../shared/services/user.service';
import {AchievementService} from '../../services/achievement.service';
import {take} from 'rxjs/operators';

@Component({
  selector: 'app-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.scss'],
})
export class CommentsComponent implements OnInit, OnDestroy {
  @Input() video: Video;
  comments: any = [];
  commentsSubscription: Subscription = new Subscription();
  comment: string = '';

  constructor(private modalCtrl: ModalController,
              public _comment: CommentService,
              private _achievement: AchievementService,
              private _user: UserService) {
  }

  ngOnInit() {
    this.commentsSubscription = this._comment.getAllByVideo(this.video.key).subscribe(async data => {
      this.comments = await Promise.all(data.map(async (comment: any) => ({
        ...comment,
        user: await this._user.get(comment.user.id).pipe(take(1)).toPromise()
      })));
    });
  }

  sendComment() {
    this._comment.add(this.video.key, {
      comment: this.comment,
      createdAt: Date.now(),
      user: this._achievement.getReference(`users/${UserService.uid}`),
      trash: false
    })

    this.comment = ''
  }

  ngOnDestroy(): void {
    this.commentsSubscription.unsubscribe();
  }
}
