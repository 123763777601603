import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {AuthRoutes} from './auth-routing.module';
import {RouterModule} from '@angular/router';
import {LoginComponent} from './pages/login/login.component';
import {IonicModule} from '@ionic/angular';
import {AuthComponent} from './auth.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RegisterComponent} from './pages/register/register.component';
import {ResetPasswordComponent} from './pages/reset-password/reset-password.component';
import {WelcomeComponent} from './pages/welcome/welcome.component';


@NgModule({
  declarations: [
    AuthComponent,
    LoginComponent,
    RegisterComponent,
    ResetPasswordComponent,
    WelcomeComponent
  ],
  imports: [
    IonicModule.forRoot(),
    CommonModule,
    RouterModule.forChild(AuthRoutes),
    IonicModule,
    FormsModule,
    ReactiveFormsModule
  ]
})
export class AuthModule {
}
