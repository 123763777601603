import {Component, OnDestroy, OnInit} from '@angular/core';
import {User} from '../../../shared/interfaces/user';
import {Subscription} from 'rxjs';
import {UserService} from '../../../shared/services/user.service';
import {ActionSheetController, LoadingController, ModalController} from '@ionic/angular';
import {UpdateProfileComponent} from '../../modals/update-profile/update-profile.component';
import {ChangePasswordComponent} from '../../modals/change-password/change-password.component';
import {ChangeEmailComponent} from '../../modals/change-email/change-email.component';
import {Router} from '@angular/router';
import {Camera, CameraOptions} from '@ionic-native/camera/ngx';
import {MembershipService} from 'src/app/shared/services/membership.service';
import {AlertService} from '../../../shared/template-services/alert.service';
import {CancellationOpinionComponent} from '../../modals/cancellation-opinion/cancellation-opinion.component';
import {AuthService} from 'src/app/shared/template-services/auth.service';
import {environment} from '../../../../environments/environment';
import {take} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit, OnDestroy {
  user: User;
  userSubscription: Subscription = new Subscription();
  profilePicture: any;
  options: CameraOptions = {
    quality: 30,
    destinationType: this.camera.DestinationType.DATA_URL,
    encodingType: this.camera.EncodingType.JPEG,
    mediaType: this.camera.MediaType.PICTURE,
    sourceType: 0,
  };
  isTesting: boolean;

  constructor(private _user: UserService,
              public modalController: ModalController,
              private router: Router,
              private loadingController: LoadingController,
              private camera: Camera,
              private _alert: AlertService,
              private _membership: MembershipService,
              private actionSheetCtrl: ActionSheetController,
              private http: HttpClient,
              private _auth: AuthService) {
  }

  async ngOnInit() {
    this.getIsTesting();

    await this._auth.loadFirebaseUser();
    this.userSubscription = this._user.getCurrentUser().subscribe(async user => {
      this.user = user;
    });
  }

  ngOnDestroy() {
    this.userSubscription.unsubscribe();
  }

  async getIsTesting() {
    const resp: any = await this.http.post(`${environment.apiBaseURL}/isTesting`, {
      version: environment.appVersion
    }).pipe(take(1)).toPromise();

    this.isTesting = resp.ok;
  }

  async editUser() {
    const modal = await this.modalController.create({
      component: UpdateProfileComponent,
      swipeToClose: true,
      componentProps: {
        user: {...this.user},
      }
    });

    return await modal.present();
  }

  async changePassword() {
    const modal = await this.modalController.create({
      component: ChangePasswordComponent,
      swipeToClose: true,
      componentProps: {
        user: {...this.user},
      }
    });

    return await modal.present();
  }

  async changeEmail() {
    const modal = await this.modalController.create({
      component: ChangeEmailComponent,
      swipeToClose: true,
      componentProps: {
        user: {...this.user},
      }
    });

    return await modal.present();
  }

  renewalMembership() {
    this.router.navigateByUrl('/user/memberships');
  }

  hasActiveMembership() {
    if (!this.user) return false;

    return !!this.user
      && !!this.user.membership
      && this.user.membership.reference.id != 'y1pI4abvw2QWZGyM7v0F'
      && (this.user.membership.endTime == 95646466800000
        || this.user.membership.endTime == 32503705200000
        || this.user.membership.endTime == 1831060208000);
  }

  async cancelSubscription() {
    if (await this._alert.confirm('¿Estás seguro que deseas cancelar esta membresía?', 'Podrás seguir disfrutando de los beneficios hasta la fecha de vencimiento', 'Aceptar', 'Cerrar')) {

      const modal = await this.modalController.create({
        component: CancellationOpinionComponent,
        swipeToClose: false,
        backdropDismiss: false,
        keyboardClose: false,
        componentProps: {
          user: {...this.user},
        }
      });

      await modal.present();
      await modal.onWillDismiss();

      const resp: { ok: boolean, endTime: number } = await this._membership.cancel(this._user.user.subscription, this._user.user.email);

      if (!resp.ok) return this._alert.presentToast('Hubo un error');

      await this._user.update(this._user.user.key, {
        membership: {
          ...this._user.user.membership,
          endTime: resp.endTime,
        }
      } as User);

      this._alert.presentToast('Se ha cancelado los pagos recurrentes a esta membresía');
    }
  }

  async changeCard() {
    if (await this._alert.confirm(  '¿Estás seguro que deseas cambiar de tarjeta?',
      'Cancelaremos tu membresía y la podrás renovar con una nueva tarjeta una vez que se termine el tiempo restante de tu membresía actual',
      'Aceptar',
      'Cerrar')) {

      const modal = await this.modalController.create({
        component: CancellationOpinionComponent,
        swipeToClose: false,
        backdropDismiss: false,
        keyboardClose: false,
        componentProps: {
          user: {...this.user},
        }
      });

      await modal.present();
      await modal.onWillDismiss();

      const resp: { ok: boolean, endTime: number } = await this._membership.cancel(this._user.user.subscription, this._user.user.email);

      if (!resp.ok) return this._alert.presentToast('Hubo un error');

      await this._user.update(this._user.user.key, {
        membership: {
          ...this._user.user.membership,
          endTime: resp.endTime,
        }
      } as User);

      this._alert.presentToast('Se ha cancelado los pagos recurrentes a esta membresía');
    }
  }

  openFile() {
    this.camera.getPicture(this.options)
      .then(resp => {
        console.log(resp);
        this.changeImage(resp);
      })
      .catch(err => {
        console.log(`:: CAMERA ERROR ${err}`);
      });
  }

  async changeImage(image) {
    this.presentLoading();
    this.profilePicture = 'data:image/jpeg;base64,' + image;
    let photoUrl = await this._user.uploadPicturePrfile(this.profilePicture, this.user.key);
    await this._user.update(this.user.key, {photoUrl} as User);
    this._alert.presentToast('Has cambiado de foto satisfactoriamente');
    this.loadingController.dismiss();
  }

  async presentLoading(): Promise<void> {
    const loading = await this.loadingController.create({
      message: 'Cambiando foto',
      spinner: 'circular'
    });
    return await loading.present();
  }

  async handleActions() {
    const actionSheet = await this.actionSheetCtrl.create({
      buttons: [
        {
          text: 'Editar información',
          handler: () => {
            this.editUser();
          }
        },
        {
          text: 'Cambiar contraseña',
          handler: () => {
            this.changePassword();
          }
        },
        {
          text: 'Cambiar correo electrónico',
          handler: () => {
            this.changeEmail();
          }
        },
        {
          text: 'Renovar membresía',
          handler: () => {
            this.renewalMembership();
          }
        },
        (this.hasActiveMembership()
            ? {
              text: 'Cancelar membresía',
              handler: () => {
                this.cancelSubscription();
              }
            }
            : {}
        ),
        (this.hasActiveMembership()
            ? {
              text: 'Cambiar tarjeta',
              handler: () => {
                this.changeCard();
              }
            }
            : {}
        ),
        {
          text: 'Eliminar cuenta',
          handler: () => {
            this._user.deleteAccount();
          }
        },
      ].filter(button => !!button.text)
        .filter(button => button.text != 'Renovar membresía' || !this.isTesting),
    });

    await actionSheet.present();
  }
}
