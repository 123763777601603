import { Routes } from '@angular/router';
import {AuthModule} from './auth/auth.module';
import {LoginGuard} from './auth/guards/login.guard';
import {UserModule} from './user/user.module';
import {AuthGuard} from './shared/guards/auth.guard';
import {UserGuard} from './user/guards/user.guard';

export const appRoutes: Routes = [
  {
    path: 'auth',
    loadChildren: () => AuthModule,
    canActivate: [LoginGuard]
  },
  {
    path: 'user',
    loadChildren: () => UserModule,
    canActivate: [AuthGuard, UserGuard]
  },
  {
    path: '**',
    redirectTo: 'auth'
  }
];
