import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-toggle-button',
  templateUrl: './toggle-button.component.html',
  styleUrls: ['./toggle-button.component.scss'],
})
export class ToggleButtonComponent implements OnInit {
  @Input() model: any;
  @Input() label: string = '';
  @Output() modelChange = new EventEmitter<any>();

  constructor() {
  }

  ngOnInit() {
  }

  toggle() {
    this.model = !this.model;
    this.modelChange.emit(this.model);
  }
}
