import {Injectable} from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {FirebaseDataService} from '../template-services/firebase-data.service';
import {Observable} from 'rxjs';
import {Setting} from '../interfaces/setting';

@Injectable({
  providedIn: 'root'
})
export class SettingService {

  constructor(private db: FirebaseDataService,) {
  }

  getAll(): Observable<Setting[]> {
    return this.db.colWithIds$<Setting>('settings');
  }
}
