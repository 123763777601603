import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MembershipsComponent } from './pages/memberships/memberships.component';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { RouteReuseStrategy, RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UserComponent } from './user.component';
import { UserRoutes } from './user-routing.module';
import { PaymentHistoryComponent } from './pages/payment-history/payment-history.component';
import { VideoDetailComponent } from './pages/video-detail/video-detail.component';
import { SharedModule } from '../shared/shared.module';
import { CategoriesComponent } from './pages/categories/categories.component';
import { VideosHistoryComponent } from './pages/videos-history/videos-history.component';
import { FavoritesComponent } from './pages/favorites/favorites.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { UpdateProfileComponent } from './modals/update-profile/update-profile.component';
import { ChangePasswordComponent } from './modals/change-password/change-password.component';
import { ChangeEmailComponent } from './modals/change-email/change-email.component';
import { MembershipProfileComponent } from './pages/membership-profile/membership-profile.component';
import { MembershipExpiredComponent } from './modals/membership-expired/membership-expired.component';
import { VideoListComponent } from './pages/video-list/video-list.component';
import { HomeComponent } from './pages/home/home.component';
import { SearchComponent } from './modals/search/search.component';
import { AddCardPaymentComponent } from './modals/add-card-payment/add-card-payment.component';
import { PaymentComponent } from './modals/payment/payment.component';
import { PurchaseDetailsComponent } from './modals/purchase-details/purchase-details.component';
import { CalendarComponent } from './pages/calendar/calendar.component';
import { ChallengesComponent } from './pages/challenges/challenges.component';
import { RecipesComponent } from './pages/recipes/recipes.component';
import { RecipeTypeLabelPipe } from './pipes/recipe-type-label.pipe';
import { CancellationOpinionComponent } from './modals/cancellation-opinion/cancellation-opinion.component';
import { WorkoutSeriesComponent } from './pages/workout-series/workout-series.component';
import { WorkoutSerieDetailsComponent } from './pages/workout-serie-details/workout-serie-details.component';
import { VideoPlayerComponent } from './modals/video-player/video-player.component';
import { AllVideosComponent } from './pages/all-videos/all-videos.component';
import { MainPageTabsComponent } from './pages/main-page-tabs/main-page-tabs.component';
import { ProgramsPage } from './pages/programs/programs.page';
import { MyAchievementsComponent } from './pages/my-achievements/my-achievements.component';
import { CompletedGoalComponent } from './pages/completed-goal/completed-goal.component';
import { CommentsComponent } from './modals/comments/comments.component';
import { UsersSeenCurrentVideoComponent } from './modals/users-seen-current-video/users-seen-current-video.component';
import { SummaryComponent } from './pages/profile/summary/summary.component';
import { AchievementComponent } from './pages/my-achievements/achievement/achievement.component';
import { ToggleButtonComponent } from './modals/search/toggle-button/toggle-button.component';
import { ActivityComponent } from './page/activity/activity.component';
import { FriendsComponent } from './pages/friends/friends.component';
import { AddFriendComponent } from './modals/add-friend/add-friend.component';
import { BannerComponent } from './components/banner/banner.component';
import { ProgressComponent } from './components/progress/progress.component';
import { MealPlansComponent } from '../admin/user/pages/meal-plans/meal-plans.component';
import { VideoSearchComponent } from './pages/video-search/video-search.component';
import { CategoryListComponent } from './pages/category-list/category-list.component';

@NgModule({
  declarations: [
    UserComponent,
    MembershipsComponent,
    PaymentHistoryComponent,
    VideoDetailComponent,
    CategoriesComponent,
    VideosHistoryComponent,
    FavoritesComponent,
    ProfileComponent,
    UpdateProfileComponent,
    ChangePasswordComponent,
    ChangeEmailComponent,
    MembershipProfileComponent,
    MembershipExpiredComponent,
    VideoListComponent,
    CategoryListComponent,
    HomeComponent,
    SearchComponent,
    AddCardPaymentComponent,
    PaymentComponent,
    PurchaseDetailsComponent,
    CalendarComponent,
    ChallengesComponent,
    RecipesComponent,
    RecipeTypeLabelPipe,
    CancellationOpinionComponent,
    WorkoutSeriesComponent,
    WorkoutSerieDetailsComponent,
    VideoPlayerComponent,
    AllVideosComponent,
    MainPageTabsComponent,
    ProgramsPage,
    MyAchievementsComponent,
    CompletedGoalComponent,
    CommentsComponent,
    UsersSeenCurrentVideoComponent,
    SummaryComponent,
    AchievementComponent,
    ToggleButtonComponent,
    ActivityComponent,
    FriendsComponent,
    AddFriendComponent,
    BannerComponent,
    ProgressComponent,
    MealPlansComponent,
    VideoSearchComponent
  ],
  imports: [
    IonicModule.forRoot(),
    CommonModule,
    RouterModule.forChild(UserRoutes),
    IonicModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule
  ],
  exports: [
    BannerComponent
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ]
})
export class UserModule {
}
