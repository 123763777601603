import { Routes } from '@angular/router';
import { MembershipsComponent } from './pages/memberships/memberships.component';
import { UserComponent } from './user.component';
import { PaymentHistoryComponent } from './pages/payment-history/payment-history.component';
import { VideoDetailComponent } from './pages/video-detail/video-detail.component';
import { CategoriesComponent } from './pages/categories/categories.component';
import { VideosHistoryComponent } from './pages/videos-history/videos-history.component';
import { FavoritesComponent } from './pages/favorites/favorites.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { MembershipGuard } from './guards/membership.guard';
import { VideoListComponent } from './pages/video-list/video-list.component';
import { HomeComponent } from './pages/home/home.component';
import { ChallengesComponent } from './pages/challenges/challenges.component';
import { RecipesComponent } from './pages/recipes/recipes.component';
import { WorkoutSerieDetailsComponent } from './pages/workout-serie-details/workout-serie-details.component';
import { WorkoutSeriesComponent } from './pages/workout-series/workout-series.component';
import { AllVideosComponent } from './pages/all-videos/all-videos.component';
import { MainPageTabsComponent } from './pages/main-page-tabs/main-page-tabs.component';
import { ProgramsPage } from './pages/programs/programs.page';
import { MyAchievementsComponent } from './pages/my-achievements/my-achievements.component';
import { ActivityComponent } from './page/activity/activity.component';
import { FriendsComponent } from './pages/friends/friends.component';
import { MealPlansComponent } from '../admin/user/pages/meal-plans/meal-plans.component';
import { VideoSearchComponent } from './pages/video-search/video-search.component';
import { CategoryListComponent } from './pages/category-list/category-list.component';

export const UserRoutes: Routes = [
  {
    path: '',
    component: UserComponent,
    children: [
      {
        path: 'tabs',
        component: MainPageTabsComponent,
        data: {
          shouldReuse: false,
          key: 'home'
        },
        children: [
          {
            path: 'memberships/:categoryName',
            component: MembershipsComponent
          }
        ]
      },
      {
        path: 'profile',
        component: ProfileComponent
      },
      {
        path: 'memberships',
        component: MembershipsComponent
      },
      {
        path: 'membership',
        component: MembershipsComponent
      },
      {
        path: 'favorites',
        component: FavoritesComponent,
        canActivate: [MembershipGuard]
      },
      {
        path: 'home',
        component: HomeComponent
      },
      {
        path: 'all-videos',
        component: AllVideosComponent
      },
      {
        path: 'programs',
        component: ProgramsPage
      },
      {
        path: 'calendar',
        loadChildren: () => import('./pages/calendar-v2/calendar-v2.module').then(m => m.CalendarV2PageModule)
      },
      {
        path: 'payment-history',
        component: PaymentHistoryComponent
      },
      {
        path: 'video-detail',
        component: VideoDetailComponent,
        canActivate: [MembershipGuard]
      },
      {
        path: 'recipes',
        component: RecipesComponent,
        canActivate: [MembershipGuard]
      },
      {
        path: 'videos-history',
        component: VideosHistoryComponent,
        canActivate: [MembershipGuard]
      },
      {
        path: 'search-videos',
        component: VideoSearchComponent
      },
      {
        path: 'videos',
        component: CategoryListComponent
      },
      {
        path: 'videos/:category',
        component: CategoryListComponent
      },
      {
        path: 'video-list',
        component: VideoListComponent,
        data: {
          shouldReuse: false,
          key: 'video-list'
        }
      },
      {
        path: 'video-list/:category',
        component: VideoListComponent,
        data: {
          shouldReuse: false,
          key: 'video-list-category'
        }
      },
      {
        path: 'categories',
        component: CategoriesComponent
      },
      {
        path: 'challenges',
        component: ChallengesComponent
      },
      {
        path: 'workout-series',
        component: WorkoutSeriesComponent
      },
      {
        path: 'my-achievements',
        component: MyAchievementsComponent
      },
      {
        path: 'activity',
        component: ActivityComponent
      },
      {
        path: 'friends',
        component: FriendsComponent
      },
      {
        path: 'meal-plans',
        component: MealPlansComponent,
        canActivate: [MembershipGuard]
      },
      {
        path: 'workout-serie-details/:section/:workoutSerieKey',
        component: WorkoutSerieDetailsComponent,
        canActivate: [MembershipGuard]
      },
      {
        path: 'all-recipes',
        loadChildren: () => import('./pages/all-recipes/all-recipes.module').then(m => m.AllRecipesPageModule),
        canActivate: [MembershipGuard]
      },
      {
        path: 'all-recipes-by/:category',
        loadChildren: () => import('./pages/all-recipes/all-recipes.module').then(m => m.AllRecipesPageModule),
        canActivate: [MembershipGuard]
      },
      {
        path: '**',
        redirectTo: 'home'
      }
    ]
  }
];
