import { Component, OnInit } from '@angular/core';
import { MealPlanService } from '../../../../user/services/meal-plan.service';
import { take } from 'rxjs/operators';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';

@Component({
  selector: 'app-meal-plans',
  templateUrl: './meal-plans.component.html',
  styleUrls: ['./meal-plans.component.scss']
})
export class MealPlansComponent implements OnInit {
  mealPlans = [];

  constructor(private _mealPlan: MealPlanService,
              private iab: InAppBrowser) {
  }

  async ngOnInit() {
    this.mealPlans = await this._mealPlan.getAll().pipe(take(1)).toPromise();
  }

  downloadPdf(url) {
    this.iab.create(url, '_blank');
  }

}
