import {Component, OnInit} from '@angular/core';
import {AchievementService} from '../../../services/achievement.service';
import {UserService} from '../../../../shared/services/user.service';
import {take} from 'rxjs/operators';
import * as _ from 'lodash';

@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss'],
})
export class SummaryComponent implements OnInit {
  lastAchievements: any[] = [];
  myAchievements: any[] = [];

  constructor(private _achievement: AchievementService) {
  }

  async ngOnInit() {
    let data: any = await this._achievement.getMyAchievements(UserService.uid).pipe(take(1)).toPromise();
    data = data.map(item => ({...item, ref: this._achievement.getReference(`achievements/${item.key}`)}));
    data = await this._achievement.populate(data, ['ref']);

    this.myAchievements = data;
    const lastAchievements = [];

    data.forEach((achievement) => {
      achievement.completedGoals.forEach((goal) => {
        lastAchievements.push({
          achievement,
          completedGoal: goal,
        });
      });
    });

    this.lastAchievements = (_.orderBy(lastAchievements, 'completedGoal.date', 'desc')).slice(0, 3);
  }

}
