export const environment = {
    production: false,
    firebase: {
      apiKey: 'AIzaSyBDdYC6TcG44JlQhE7kZbozKXgQ_7kYaUM',
      authDomain: 'umana-dev.firebaseapp.com',
      databaseURL: 'https://umana-dev.firebaseio.com',
      projectId: 'umana-dev',
      storageBucket: 'umana-dev.appspot.com',
      messagingSenderId: '54329363226',
      appId: '1:54329363226:web:c33d30adb58cee4f993aa3',
      measurementId: 'G-2KBN3SMVJR'
    },
    // apiBaseURL: 'http://localhost:5001/umana-dev/us-central1',
    apiBaseURL: 'https://us-central1-umana-dev.cloudfunctions.net',
    appVersion: '2.27.0'
  }

// Dev
//
// export const environment = {
//   production: false,
//   firebase: {
//     apiKey: "AIzaSyBaRrapdrBjeZ8Ylg1C4GvZDZEzXI_DZ3I",
//     authDomain: "umana-cb18b.firebaseapp.com",
//     projectId: "umana-cb18b",
//     storageBucket: "umana-cb18b.appspot.com",
//     messagingSenderId: "238719027938",
//     appId: "1:238719027938:web:4e2c1218d7cb8208cdacbb",
//     measurementId: "G-6NDLJC9FYV"
//   },
//   // apiBaseURL: 'http://localhost:5001/umana-dev/us-central1',
//   apiBaseURL: 'https://us-central1-umana-dev.cloudfunctions.net',
//   // apiBaseURL: 'http://localhost:5001/umana-cb18b/us-central1',
//   appVersion: '2.1.4'
// }
