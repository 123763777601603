import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../../shared/services/user.service';
import { MenuController, ModalController, Platform } from '@ionic/angular';
import { SearchComponent } from '../../modals/search/search.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  menuItems = [
    {
      label: 'Calendario',
      path: '/user/calendar'
    },
    {
      label: 'Todas las clases',
      path: '/user/videos'
    },
    {
      label: 'Planes de alimentación',
      path: '/user/meal-plans'
    },
    {
      label: 'Recetas',
      path: '/user/recipes'
    },
    {
      label: 'Programas',
      path: '/user/programs'
    },
    // {
    //   label: 'Guías de viaje y más',
    //   path: '/user/workout-series'
    // },
    {
      label: 'Actividad',
      path: '/user/activity'
    },
    {
      label: 'Rider',
      path: '/user/all-videos?program=rider'
    }
  ];
  activePath = '/user/programs';
  user: any;
  userSubscription: any;
  isExpiredUser: boolean = false;
  isMobile: boolean = true;
  isGuest: boolean = false;

  constructor(
    public router: Router,
    private plt: Platform,
    private _user: UserService,
    private modal: ModalController,
    public menuCtrl: MenuController,
  ) {
    this.userSubscription = this._user.getCurrentUser().subscribe(async user => {
      this.user = user;
      this.isGuest = this.user.key == "56obq2m1CTPZKmrBQn7JLkC9fcg1"

      this.isExpiredUser = !this._user.user.membership || new Date().getTime() > this._user.user.membership.endTime || this._user.user?.membership?.reference?.id == 'y1pI4abvw2QWZGyM7v0F';
    });
  }

  async ngOnInit() {
    await this.plt.ready();

    this.isMobile = this.plt.is('ios') || this.plt.is('android') || this.plt.is('mobileweb') || this.plt.is('mobile');

    this.activePath = this.router.url;
  }

  async openSearch() {
    const modal: any = await this.modal.create({
      component: SearchComponent
    });

    modal.present();
  }
}
