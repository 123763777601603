import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'duration'
})
export class DurationPipe implements PipeTransform {

  transform(durationInSeconds: number,): string {
    const hours = Math.floor(durationInSeconds / 3600);
    const minutes = Math.floor((durationInSeconds - (hours * 3600)) / 60);
    const seconds = durationInSeconds - (hours * 3600) - (minutes * 60);

    let result = '';
    if (hours > 0) {
      result += ('0' + hours).slice(-2) + ':';
    }
    result += ('0' + minutes).slice(-2) + ':';
    result += ('0' + seconds).slice(-2);
    return result;
  }
}
