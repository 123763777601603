import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { IonSlides, ModalController, Platform } from '@ionic/angular';
import { AuthService } from '../../../shared/template-services/auth.service';
import { AlertService } from '../../../shared/template-services/alert.service';
import { Router } from '@angular/router';
import { UserService } from '../../../shared/services/user.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { RecipeService } from '../../services/recipe.service';
import { take } from 'rxjs/operators';
import * as _ from 'lodash';
import { SettingService } from '../../../shared/services/setting.service';
import { MembershipService } from '../../../shared/services/membership.service';
import { RecipeModalComponent } from '../calendar-v2/components/recipe-modal/recipe-modal.component';

@Component({
  selector: 'app-recipes',
  templateUrl: './recipes.component.html',
  styleUrls: ['./recipes.component.scss'],
  animations: [
    trigger('panelState', [
      state('closed', style({ height: '43px', overflow: 'hidden' })),
      state('open', style({ height: '*' })),
      transition('closed <=> open', animate('300ms ease-in-out'))
    ])
  ]
})
export class RecipesComponent implements OnInit, OnDestroy {
  @ViewChild('slideWithNav', { static: false })
  slideWithNav: IonSlides;
  isUser: boolean = false;
  userSubscribe: Subscription = new Subscription();
  isWeb: boolean = false;
  recipes: any[] = [];
  wellnessSection = {
    title: '',
    titleColor: '',
    imageUrl: '',
    imageUrlWeb: '',
    bubbleColor: ''
  };
  memberships;
  categories: any[] = [];

  constructor(
    private _auth: AuthService,
    private _alert: AlertService,
    public router: Router,
    public modalController: ModalController,
    private _user: UserService,
    private _recipes: RecipeService,
    private _settings: SettingService,
    private _membership: MembershipService,
    private plt: Platform
  ) {
    this.loadSettings();
  }

  async ngOnInit() {
    await this._auth.getCurrentUser();
    this.memberships = await this._membership.getAll().pipe(take(1)).toPromise();

    this.plt.ready().then(() => {
      if (this.plt.is('desktop')) {
        return this.isWeb = true;
      }
    });

    this.userSubscribe = this._user.getCurrentUser().subscribe(data => {
      this._user.user = data;
    });

    this.recipes = _.orderBy(await this._recipes.getAllV2().pipe(take(1)).toPromise(), 'createdAt', 'desc');
    this.categories = await this._recipes.getCategories().pipe(take(1)).toPromise();
  }

  loadSettings() {
    this._settings.getAll().pipe(take(1)).subscribe(data => {
      const indexedSettings: any = _.keyBy(data, 'key');
      this.wellnessSection = indexedSettings['wellness'];
    });
  }


  hasFreeTrialMembership(membershipKey: string): boolean {
    if (membershipKey == 'y1pI4abvw2QWZGyM7v0F') return true;

    const membership = this.memberships.find(membership => membership.key == membershipKey);

    return !!membership && membership.isFree;
  }

  ngOnDestroy() {
    this.userSubscribe.unsubscribe();
  }

  openRecipe(recipe: any) {
    if (this.hasFreeTrialMembership(this._user.user.membership.reference.id)) {
      return this._alert.presentAlert('No cuentas con la membresía para ver esta receta', '');
    }

    const modal = this.modalController.create({
      component: RecipeModalComponent,
      componentProps: {
        recipe: recipe
      }
    });

    modal.then(m => m.present());
  }

  goToCategory(category: any) {
    this.router.navigateByUrl('user/all-recipes-by/' + category.key)
  }
}
