import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AlertController, LoadingController, ModalController, Platform } from '@ionic/angular';
import { ApplePay } from '@ionic-native/apple-pay/ngx';
import { Observable } from 'rxjs';
import { Membership } from '../../../shared/interfaces/membership';
import { MembershipService } from '../../../shared/services/membership.service';
import { take } from 'rxjs/operators';
import { AuthService } from '../../../shared/template-services/auth.service';
import { UserService } from '../../../shared/services/user.service';
import { Coupon } from '../../../admin/interfaces/coupon';
import { CouponService } from '../../services/coupon.service';
import { PeriodOfTime } from '../../../shared/enums/period-of-time.enum';
import { PeriodType } from '../../../shared/enums/period-type.enum';
import { User } from '../../../shared/interfaces/user';
import { HistoryService } from '../../services/history.service';
import { DiscountType } from '../../../shared/enums/discount-type.enum';
import { AlertService } from '../../../shared/template-services/alert.service';
import { Router } from '@angular/router';
import { PaymentComponent } from '../../modals/payment/payment.component';
import { IAPProduct, InAppPurchase2 } from '@ionic-native/in-app-purchase-2/ngx';
import { PaymentType } from '../../../shared/enums/payment-type.enum';
import { Status } from '../../../shared/enums/status.enum';
import { SettingService } from '../../../shared/services/setting.service';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { PurchaseDetailsComponent } from '../../modals/purchase-details/purchase-details.component';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-memberships',
  templateUrl: './memberships.component.html',
  styleUrls: ['./memberships.component.scss']
})
export class MembershipsComponent implements OnInit {
  currencyCode: string = 'MXN';
  countryCode: string = 'MX';
  memberships$: Observable<Membership[]>;
  coupons$: Observable<Coupon[]>;
  memberships: Membership[];
  coupons: Coupon[];
  membership$: Observable<Membership>;
  currentMembership: Membership;
  currentUser: any;
  user: any;
  currentDate: number = (new Date()).getTime();
  couponUsed = { indexCoupon: null, indexMembership: null, use: false, membershipKey: null };
  periodOfTime = PeriodOfTime;
  periodType = PeriodType;
  products: IAPProduct[] = [];
  membership: any;
  isCardPay: boolean = false;
  isIos: boolean = true;
  paymentType = PaymentType;
  status = Status;
  setting: any;
  isTesting: boolean = false;
  isFetched: boolean = false;

  constructor(private applePay: ApplePay,
    public alertController: AlertController,
    private _membership: MembershipService,
    private _auth: AuthService,
    private _user: UserService,
    private _coupons: CouponService,
    private _history: HistoryService,
    private router: Router,
    private modalController: ModalController,
    private _alert: AlertService,
    private store: InAppPurchase2,
    private http: HttpClient,
    public plt: Platform,
    private modalCtrl: ModalController,
    private ref: ChangeDetectorRef,
    private loadingCtrl: LoadingController,
    private _settings: SettingService,
    private iab: InAppBrowser) {
  }

  async ngOnInit() {
    this.getIsTesting();

    this.setting = await this._settings.getAll().pipe(take(1)).toPromise();
    await this.getMemberships();
    await this.getCurrentUser();
    await this.getCoupons();

    await this.plt.ready();

    this.isIos = this.plt.is('ios');
    this.isCardPay = !this.isIos;

    this.store.verbosity = this.store.DEBUG;
    this.products = [];
    if (this.plt.is('ios')) {
      this.setupListeners();

      this.store.ready(() => {
        this.products = [];
        this.products = this.store.products;
        this.ref.detectChanges();
      });
    }
  }

  setupListeners() {
    this.store.when('product')
      .approved(async (p: IAPProduct) => {
        let membership = this.memberships.find(membership => membership.productId == p.id);
        let membershipApple = {
          ...membership,
          paymentType: this.paymentType.STRIPE,
          status: this.status.PAID
        };
        let endTime = await this.contractMembership(membershipApple);
        p.expiryDate = new Date(endTime);
        this.ref.detectChanges();

        p.finish();
      });
  }

  purchase(product: IAPProduct) {
    this.store.order(product).then(p => {
    }, e => {
      this._alert.presentAlert('Failed', `Failed to purchase: ${e}`);
    });
  }

  restore() {
    this.store.refresh();
  }

  async presentAlert(message) {
    const alert = await this.alertController.create({
      header: 'Alerta',
      message: message,
      buttons: ['OK']
    });

    await alert.present();
  }

  async getMemberships() {
    await this.presentLoading('Cargando membresías');
    this.memberships$ = this._membership.getAll();
    this.memberships = await this.memberships$.pipe(take(1)).toPromise();
    console.log(this.memberships);
    await this.loadingCtrl.dismiss();
  }

  async getCurrentUser() {
    this.currentUser = await this._auth.loadFirebaseUser();
    this.user = await this._user.getSpecificUser(this.currentUser.uid);
    await this.getCurrentMembership();
  }

  async presentLoading(message: string): Promise<void> {
    const loading = await this.loadingCtrl.create({
      message,
      spinner: 'circles',
      mode: 'ios'
    });
    return await loading.present();
  }

  async getCurrentMembership() {
    this.membership$ = this._membership.get(this.user.membership.reference.id);
    this.currentMembership = await this.membership$.pipe(take(1)).toPromise();
  }

  async getCoupons() {
    this.coupons$ = this._coupons.getAll();
    this.coupons = await this.coupons$.pipe(take(1)).toPromise();
  }

  async contractMembership(membership, isInterbank = false) {

    let membershipReference = this._membership.getReference(membership.key);

    if (membership.periodOfTime == PeriodOfTime.RANGE) {
      await this._user.updateMembership(this.currentUser.uid, {
        endTime: membership.rangeEnd,
        reference: membershipReference,
        startTime: membership.rangeStart,
        status: isInterbank ? 0 : 1
      });
    }

    let endTime: number;
    if (membership.periodOfTime == PeriodOfTime.LIMITED) {
      if (membership.periodType == PeriodType.DAILY) {
        endTime = this.currentDate + (86400000 * membership.quantity);
        await this._user.updateMembership(this.currentUser.uid, {
          endTime,
          reference: membershipReference,
          startTime: this.currentDate,
          status: isInterbank ? 0 : 1
        });
      }
      if (membership.periodType == PeriodType.WEEKLY) {
        endTime = this.currentDate + (604800000 * membership.quantity);
        await this._user.updateMembership(this.currentUser.uid, {
          endTime,
          reference: membershipReference,
          startTime: this.currentDate,
          status: isInterbank ? 0 : 1
        });
      }
      if (membership.periodType == PeriodType.MONTHLY) {
        endTime = this.currentDate + (2592000000 * membership.quantity);
        await this._user.updateMembership(this.currentUser.uid, {
          endTime,
          reference: membershipReference,
          startTime: this.currentDate,
          status: isInterbank ? 0 : 1
        });
      }
      if (membership.periodType == PeriodType.ANNUAL) {
        endTime = this.currentDate + (31536000000 * membership.quantity);
        await this._user.updateMembership(this.currentUser.uid, {
          endTime,
          reference: membershipReference,
          startTime: this.currentDate,
          status: isInterbank ? 0 : 1
        });
      }
      if (membership.periodOfTime == PeriodOfTime.FOREVER) {
        await this._user.updateMembership(this.currentUser.uid, {
          endTime: 95646466800000,
          reference: membershipReference,
          startTime: membership.rangeStart,
          status: isInterbank ? 0 : 1
        });
      }
    }

    let membershipHistory = {
      ...membership,
      createdAt: this.currentDate,
      endTime,
      status: isInterbank ? 0 : 1
    };

    await this._history.add(this.currentUser.uid, membershipHistory);

    this.currentMembership = membership;

    this._user.update(this.currentUser.uid, { pendingMembership: null } as User);

    if (isInterbank) {

      const alert = await this.alertController.create({
        cssClass: 'my-custom-class',
        header: `El plan "${membership.name}" ha sido contratado con status PENDIENTE`,
        subHeader: 'Para ver detalles del status puede ir a la sección "Historial de Pagos" y seleccionar el pago pendiente',
        buttons: [
          {
            text: 'Aceptar',
            role: 'cancel',
            cssClass: 'modal-button-cancel',
            handler: async (blah) => {
              const modal = await this.modalCtrl.create({
                component: PurchaseDetailsComponent,
                componentProps: {
                  payment: membershipHistory
                }
              });
              await modal.present();

              await modal.onWillDismiss();

              window.location.reload();
            }
          }
        ]
      });

      await alert.present();
    } else {
      this.presentAlert(`El plan "${membership.name}" ha sido contratado exitosamente`);

      return endTime;
    }
  }

  async checkIsCouponUsed(isBought: boolean) {
    if (isBought) {
      this.coupons[this._user.user.pendingMembership.couponUsed.indexCoupon].used++;
      await this._coupons.update(this.coupons[this._user.user.pendingMembership.couponUsed.indexCoupon].key, this.coupons[this._user.user.pendingMembership.couponUsed.indexCoupon]);
    }

    if (this.couponUsed.indexMembership) {
      this.memberships[this.couponUsed.indexMembership].price = this.memberships[this.couponUsed.indexMembership].oldPrice;
      this.memberships[this.couponUsed.indexMembership].oldPrice = null;
    }

    this.couponUsed.indexMembership = null;
    this.couponUsed.indexCoupon = null;
    this.couponUsed.use = false;
    this.couponUsed.membershipKey = null;
  }

  async applyCoupon(membership, membershipIndex: number) {
    this.presentAlertInput(`Aplicar cupón para la membresía de ${membership.name}`, 'Ingresar código', 'Aplicar', membership, membershipIndex);
  }

  getIfMembershipItsOver(membership) {
    if (membership.periodOfTime != PeriodOfTime.RANGE) {
      return true;
    }
    return membership.rangeEnd > this.currentDate;
  }

  async presentAlertMultipleButtons(membership: Membership) {
    let membershipCard = {
      ...membership,
      paymentType: this.paymentType.STRIPE,
      status: this.status.PAID
    };
    if (this.plt.is('ios')) {
      return this.paymentCard(membershipCard);
    }

    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Métodos de pagos',
      subHeader: 'Tarjeta',
      buttons: [
        {
          text: 'Tarjeta',
          handler: () => {
            this.openMembershipStripe();
          }
        },
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'modal-button-cancel',
          handler: () => {
            console.log('Confirm Cancel: blah');
          }
        }
      ]
    });

    await alert.present();
  }

  async paymentInterbank(membership): Promise<void> {
    const alert = await this.alertController.create({
      header: `¿Estás seguro de que quieres realizar la contratación de la membresía ${membership.name}?`,
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
          }
        },
        {
          text: 'Confirmar',
          handler: async () => {
            await this.contractMembership(membership, true);
          }
        }
      ]
    });

    await alert.present();
  }

  async paymentCard(membership) {
    const modal = await this.modalController.create({
      component: PaymentComponent,
      cssClass: 'my-custom-class',
      componentProps: {
        membership
      }
    });

    modal.onDidDismiss().then(data => {
      if (!!data.data) {
        this.contractMembership((membership));
      }
    });
    return await modal.present();
  }

  async presentAlertInput(title: string, textInput: string, textButton: string, membership?, membershipIndex?) {
    const alert = await this.alertController.create({
      header: title,
      inputs: [
        {
          name: 'code',
          type: 'text',
          placeholder: textInput
        }
      ],
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            console.log('Confirm Cancel');
          }
        }, {
          text: textButton,
          handler: async data => {
            if (!!data.code) {
              let indexCoupon = this.coupons.findIndex(coupon => coupon.code == data.code);
              if (indexCoupon == -1) {
                return this.presentAlert(`No se encuentra el código`);
              }
              let membershipReference = this.coupons[indexCoupon].memberships.find(m => m.id == membership.key);
              if (this.coupons[indexCoupon].endTime < this.currentDate) {
                return this.presentAlert(`Este código ya caducó`);
              }
              if (!membershipReference) {
                return this.presentAlert(`Este código no funciona en esta membresía`);
              }
              if (this.coupons[indexCoupon].used >= this.coupons[indexCoupon].available) {
                return this.presentAlert(`Este código ya alcanzo su límite de usos`);
              }
              if (this.couponUsed.membershipKey == membership.key) {
                return this.presentAlert(`Ya se utilizó un cupón para esta membresía`);
              }
              let discount;
              if (this.coupons[indexCoupon].type == DiscountType.PERCENTAGE) {
                discount = membership.price - (membership.price * (this.coupons[indexCoupon].quantityDiscount / 100));
              }
              if (this.coupons[indexCoupon].type == DiscountType.DIRECT) {
                discount = membership.price - this.coupons[indexCoupon].quantityDiscount;
              }

              let membershipWithNewPrice = { ...membership };
              membershipWithNewPrice.price = discount;

              await this.checkIsCouponUsed(false);

              this.memberships[membershipIndex].oldPrice = membership.price;
              this.memberships[membershipIndex].price = discount;

              this.couponUsed.indexMembership = membershipIndex;
              this.couponUsed.indexCoupon = indexCoupon;
              this.couponUsed.use = true;
              this.couponUsed.membershipKey = membership.key;

              this.presentAlert('Se aplicó el cupón correctamente');
            }
          }
        }
      ]
    });

    await alert.present();
  }

  async openMembershipStripe() {
    await this._alert.presentAlertConfirm('Te enviaremos a una página en tu navegador ¿Deseas continuar?');
    this.iab.create('https://pagos.umana.com.mx', '_blank');
  }

  async getIsTesting() {
    const resp: any = await this.http.post(`${environment.apiBaseURL}/isTesting`, {
      version: environment.appVersion
    }).pipe(take(1)).toPromise();

    this.isTesting = resp.ok;
    this.isFetched = true;
  }
}
