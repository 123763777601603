import { Component, OnInit } from '@angular/core';

import { MenuController, ModalController, Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AuthService } from './shared/template-services/auth.service';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { Router } from '@angular/router';
import { AlertService } from './shared/template-services/alert.service';
import { UserService } from './shared/services/user.service';
import { User } from './shared/interfaces/user';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { InAppPurchase2 } from '@ionic-native/in-app-purchase-2/ngx';
import { Membership } from './shared/interfaces/membership';
import { MembershipService } from './shared/services/membership.service';
import { environment } from '../environments/environment';
import { SettingService } from './shared/services/setting.service';
import { CancellationOpinionComponent } from './user/modals/cancellation-opinion/cancellation-opinion.component';
import { FriendService } from './user/services/friend.service';
import * as _ from 'lodash';
import { VideoService } from './shared/services/video.service';
import { Video } from './shared/interfaces/video';
import * as moment from 'moment';
import { HttpClient } from '@angular/common/http';
import { SearchComponent } from './user/modals/search/search.component';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
  public selectedIndex = 0;
  public appPages = [
    {
      title: 'Calendario Semanal',
      url: '/user/calendar',
      icon: 'calendar'
    },
    {
      title: 'Todas las clases',
      url: '/user/videos',
      icon: 'videocam'
    },
    {
      title: 'Programas',
      url: '/user/programs',
      icon: 'scale'
    },
    {
      title: 'Recetas',
      url: '/user/recipes',
      icon: 'scale'
    },
    // {
    //   title: 'Guías de viaje / principiantes y máß',
    //   url: '/user/workout-series',
    //   icon: 'flame'
    // },
    {
      title: 'Planes de alimentación',
      url: '/user/meal-plans',
      icon: 'flag'
    },
    {
      title: 'Rider - indoor cycling',
      url: '/user/all-videos?program=rider',
      icon: 'barbell'
    },
    {
      title: 'Actividad',
      url: '/user/activity',
      icon: 'barbell'
    },
    {
      title: 'Membresías',
      url: '/user/membership',
      icon: 'videocam'
    },
    {
      title: 'Favoritos',
      url: '/user/favorites',
      icon: 'videocam'
    }
  ];
  user: any = null;
  isUser: boolean = false;
  person: User = null;
  memberships: Membership[];
  memberships$: Observable<Membership[]>;
  version = environment.appVersion;
  setting: any;
  email: string;
  isTesting: boolean;
  isMobile: boolean = true;

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    public _auth: AuthService,
    private iab: InAppBrowser,
    private router: Router,
    private _alert: AlertService,
    private _user: UserService,
    private _video: VideoService,
    private _friend: FriendService,
    private store: InAppPurchase2,
    private _membership: MembershipService,
    public menuCtrl: MenuController,
    private http: HttpClient,
    private modalCtrl: ModalController,
    private modal: ModalController,
    private _setting: SettingService) {
    this.initializeApp();
    this._auth.isAuthenticated();
    UserService.uidEmitter.subscribe(async userID => {
      if (userID != null) this.person = await this._user.get(userID).pipe(take(1)).toPromise();
    });
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.isMobile = this.platform.is('ios') || this.platform.is('android') || this.platform.is('mobileweb') || this.platform.is('mobile');
      this.statusBar.styleDefault();
      this.statusBar.styleLightContent();
      this.statusBar.backgroundColorByHexString('#31334c');
      this.splashScreen.hide();
    });
  }

  async ngOnInit() {
    this.getIsTesting();
    this.setting = await this._setting.getAll().pipe(take(1)).toPromise();
    this.email = 'mailto:' + this.setting[3].email;
    this._auth.$isUser.subscribe(isUser => {
      this.isUser = isUser;
    });

    this.memberships$ = this._membership.getAll();
    this.memberships = await this.memberships$.pipe(take(1)).toPromise();

    if (this.platform.is('ios')) {
      this.registerProducts();
    }

    this._auth.userEmitter.subscribe(user => this.user = user);
    const path = window.location.pathname.split('folder/')[1];
    if (path !== undefined) {
      this.selectedIndex = this.appPages.findIndex(page => page.title.toLowerCase() === path.toLowerCase());
    }

    this._friend.getRequests().subscribe(async requests => {
      const request: any = _.first(_.orderBy(requests, 'createdAt', 'desc'));

      if (request && moment(request.createdAt).isAfter(moment().subtract(1, 'minutes'))) {
        const video: Video = await this._video.get(request.video.id).pipe(take(1)).toPromise();
        const friend = await this._user.get(request.friend.id).pipe(take(1)).toPromise();

        if (await this._alert.confirm(`${friend?.firstName.toUpperCase() || ''} ${friend?.lastName.toUpperCase() || ''} te ha invitado a ver un video`, '', 'Aceptar', 'Rechazar')) {
          await this._friend.removeRequest(request.key);

          this._video.currentVideo = video;
          await this.router.navigateByUrl(`user/video-detail`);
        } else {
          await this._friend.removeRequest(request.key);
        }
      }
    });
  }

  async getIsTesting() {
    const resp: any = await this.http.post(`${environment.apiBaseURL}/isTesting`, {
      version: environment.appVersion
    }).pipe(take(1)).toPromise();

    this.isTesting = resp.ok;
  }


  registerProducts() {
    this.store.register({
      id: 'Umana_Mensual',
      type: this.store.NON_RENEWING_SUBSCRIPTION
    });

    this.store.register({
      id: 'Umana_Anual',
      type: this.store.NON_RENEWING_SUBSCRIPTION
    });

    this.store.refresh();
  }

  async signOut() {
    localStorage.setItem('isLogin', 'false');
    this.isUser = true;
    await this._auth.signOut();
  }

  async goToLogin() {
    await this._auth.signOut();
  }

  async openMembership() {
    if (this.platform.is('desktop')) {
      return this.iab.create('https://pagos.umana.com.mx', '_blank');
    }
    this.router.navigateByUrl('/user/tabs/memberships');
  }

  openIntech() {
    this.iab.create('https://tecnologiasintech.com', '_blank');
  }

  hasActiveMembership() {
    if (!this.user) return false;

    return !!this.user
      && !!this.user.membership
      && this.user.membership.reference.id != 'y1pI4abvw2QWZGyM7v0F'
      && (this.user.membership.endTime == 95646466800000
        || this.user.membership.endTime == 32503705200000
        || this.user.membership.endTime == 1831060208000);
  }

  async cancelSubscription() {
    if (await this._alert.confirm('¿Estás seguro que deseas cancelar esta membresía?', 'Podrás seguir disfrutando de los beneficios hasta la fecha de vencimiento', 'Aceptar', 'Cerrar')) {

      const modal = await this.modalCtrl.create({
        component: CancellationOpinionComponent,
        swipeToClose: false,
        backdropDismiss: false,
        keyboardClose: false,
        componentProps: {
          user: { ...this.user }
        }
      });

      await modal.present();
      await modal.onWillDismiss();

      const resp: { ok: boolean, endTime: number } = await this._membership.cancel(this._user.user.subscription, this._user.user.email);

      if (!resp.ok) return this._alert.presentToast('Hubo un error');

      await this._user.update(this._user.user.key, {
        membership: {
          ...this._user.user.membership,
          endTime: resp.endTime
        }
      } as User);

      this._alert.presentToast('Se ha cancelado los pagos recurrentes a esta membresía');
    }
  }

  async changeCard() {
    if (await this._alert.confirm('¿Estás seguro que deseas cambiar de tarjeta?',
      'Cancelaremos tu membresía y la podrás renovar con una nueva tarjeta una vez que se termine el tiempo restante de tu membresía actual',
      'Aceptar',
      'Cerrar')) {

      const modal = await this.modalCtrl.create({
        component: CancellationOpinionComponent,
        swipeToClose: false,
        backdropDismiss: false,
        keyboardClose: false,
        componentProps: {
          user: { ...this.user }
        }
      });

      await modal.present();
      await modal.onWillDismiss();

      const resp: { ok: boolean, endTime: number } = await this._membership.cancel(this._user.user.subscription, this._user.user.email);

      if (!resp.ok) return this._alert.presentToast('Hubo un error');

      await this._user.update(this._user.user.key, {
        membership: {
          ...this._user.user.membership,
          endTime: resp.endTime
        }
      } as User);

      this._alert.presentToast('Se ha cancelado los pagos recurrentes a esta membresía');
    }
  }

  async goToProfile() {
    await this.router.navigateByUrl('/user/profile');
    this.menuCtrl.close();
  }

  async openSearch() {
    const modal: any = await this.modal.create({
      component: SearchComponent
    });

    modal.present();
  }

  isBeforeOneYear(endTime: number) {
    return moment(endTime).isBefore(moment().subtract(1, 'year'));
  }

  redirectTo(url: string) {
    this.router.navigateByUrl(url);
  }
}
