import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {VideoService} from '../../../shared/services/video.service';
import {Video} from '../../../shared/interfaces/video';
import {FirebaseDataService} from '../../../shared/template-services/firebase-data.service';
import {Subscription} from 'rxjs';
import {CategoryService} from '../../../shared/services/category.service';
import {Category} from '../../../shared/interfaces/category';
import {AuthService} from '../../../shared/template-services/auth.service';
import {AlertService} from '../../../shared/template-services/alert.service';
import {ModalController} from '@ionic/angular';
import {UserService} from '../../../shared/services/user.service';
import {Location} from '@angular/common';
import * as _ from 'lodash';

@Component({
  selector: 'app-video-search',
  templateUrl: './video-search.component.html',
  styleUrls: ['./video-search.component.scss'],
})
export class VideoSearchComponent implements OnInit, OnDestroy {
  categories: Category[] = [];
  videos: Video[];
  videosSubscription: Subscription = new Subscription();
  categoriesSubscription: Subscription = new Subscription();
  isUser: boolean = false;
  userSubscribe: Subscription = new Subscription();
  calendar: any;
  search: string = '';
  filteredVideos: Video[] = [];
  timeout: any = null;
  limit: number = 20;
  maxLimit: number = 0;

  constructor(private activatedRoute: ActivatedRoute,
              private _video: VideoService,
              private db: FirebaseDataService,
              private router: Router,
              private _category: CategoryService,
              private _alert: AlertService,
              public modalController: ModalController,
              private _auth: AuthService,
              private _user: UserService,
              private location: Location) {
  }

  async ngOnInit() {
    await this._auth.getCurrentUser();
    this.userSubscribe = this._user.getCurrentUser().subscribe(data => {
      this._user.user = data;
    });

    this.videosSubscription = this._video.getAll()
      .subscribe(data => {
        this.videos = _.orderBy(data, ['createdAt'], ['desc']).filter(video => video.duration);
        this.maxLimit = this.videos.length;
        this.filteredVideos = this.videos.slice(0, this.limit);
      });
  }

  ngOnDestroy() {
    this.videosSubscription.unsubscribe();
    this.categoriesSubscription.unsubscribe();
    this.userSubscribe.unsubscribe();
  }

  async goToVideoDetail(video: Video) {
    if (!await this._auth.isAuthenticated()) {
      this.isUser = true;
      this.signOutGuest();
    }
    this._video.currentVideo = video;
    await this.router.navigateByUrl(`user/video-detail`);
  }

  async signOutGuest() {
    if (!await this._auth.isAuthenticated()) {
      if (await this._alert.confirm('Esta es una función para usuarios registrados', 'Iniciar sesión', 'Cancelar')) {
        localStorage.setItem('isLogin', 'false');
        this.router.navigateByUrl('auth');
        this.modalController.dismiss();
      }
      return;
    }
  }

  backToHome() {
    this.location.back();
  }

  filter() {
    clearTimeout(this.timeout);

    this.timeout = setTimeout(() => {
      this.filteredVideos = this.videos.filter(video => (video.title).toLowerCase().includes(this.search.toLowerCase())).slice(0, this.limit);
    }, 1000);
  }

  loadMore() {
    this.limit += 20;
    this.filteredVideos = this.videos.filter(video => (video.title).toLowerCase().includes(this.search.toLowerCase())).slice(0, this.limit);
  }
}
