import {Injectable} from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {Observable, ReplaySubject} from 'rxjs';
import {Favorite} from '../interfaces/favorite';
import firebase from 'firebase';
import {FirebaseDataService} from '../template-services/firebase-data.service';
import {map} from 'rxjs/operators';
import DocumentReference = firebase.firestore.DocumentReference;

@Injectable({
  providedIn: 'root'
})
export class FavoriteService {
  private fetched: boolean;
  private value$: ReplaySubject<any> = new ReplaySubject();

  constructor(private afs: AngularFirestore,
              private db: FirebaseDataService) {
  }

  getAll(): Observable<Favorite[]> {
    return this.afs.collectionGroup<Favorite>(`favorites`).valueChanges();
  }

  getAllByUser(userKey: string): Observable<Favorite[]> {
    if (!this.fetched) {
      this.afs.collection<Favorite>(`users/${userKey}/favorites`, ref => ref
        .where('trash', '==', false))
        .snapshotChanges()
        .pipe(
          map(actions => actions
            .map(a => {
              return {
                ...a.payload.doc.data(),
                videoKey: a.payload.doc.data().reference.id,
                key: a.payload.doc.id
              };
            })))
        .subscribe(data => {
          this.fetched = true;
          this.value$.next(data);
        });
    }

    return this.value$;
  }

  get(userKey: string, favoriteKey: string) {
    return this.afs.doc(`users/${userKey}/favorites/${favoriteKey}`).valueChanges();
  }

  async add(userKey: any, favorite: any): Promise<any> {
    return await this.afs.collection<Favorite>(`users/${userKey}/favorites`).add(favorite);
  }

  async delete(userKey: string, favoriteKey: string): Promise<void> {
    return await this.afs.doc<Favorite>(`users/${userKey}/favorites/${favoriteKey}`).update({trash: true});
  }

  update(userKey: any, favoriteKey: any, favorite: any) {
    this.afs.doc(`users/${userKey}/favorites/${favoriteKey}`).update(favorite);
  }

  getReference(membershipKey: string): DocumentReference {
    return this.db.doc(`favorites/${membershipKey}`).ref;
  }

  getAllMyFavorites(userKey: string): Observable<Favorite[]> {
    return this.db.colWithIds$<Favorite>(`users/${userKey}/favorites`, ref => ref
      .where('trash', '==', false));
  }
}
