import {EventEmitter, Injectable} from '@angular/core';
import {FirebaseDataService} from '../template-services/firebase-data.service';
import {AngularFirestore, DocumentReference} from '@angular/fire/firestore';
import {BehaviorSubject, Observable} from 'rxjs';
import {User} from '../interfaces/user';
import {take} from 'rxjs/operators';
import {AngularFireStorage} from '@angular/fire/storage';
import {HttpClient} from '@angular/common/http';
import {AlertService} from '../template-services/alert.service';
import {environment} from '../../../environments/environment';
import {AuthService} from '../template-services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  public user: any = null;
  $user: BehaviorSubject<any> = new BehaviorSubject<any>(this.user);
  static uid: string = null;
  static users: User = null;
  static userEmitter: EventEmitter<User> = new EventEmitter();
  static uidEmitter: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  static products: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);

  constructor(private db: FirebaseDataService,
              private afs: AngularFirestore,
              private http: HttpClient,
              private _alert: AlertService,
              private _auth: AuthService,
              private storage: AngularFireStorage) {
  }

  addUserByKey(userKey: string, user: User): Promise<void> {
    return this.afs.doc(`users/${userKey}`).set(user);
  }

  getAll(): Observable<User[]> {
    return this.db.colWithIds$<User>('users', ref => ref
      .where('trash', '==', false));
  }

  getLatest(): Observable<User[]> {
    return this.db.colWithIds$<User>('users', ref => ref
      .where('trash', '==', false)
      .orderBy('createdAt', 'desc').limit(5));
  }

  add(user: User): void {
    this.afs.collection(`users`).add(user);
  }

  set(userKey: string, user: User): void {
    this.afs.doc(`users/${userKey}`).set(user, {merge: true});
  }

  update(userKey: string, user: User): Promise<void> {
    return this.afs.doc(`users/${userKey}`).update(user);
  }

  updateMembership(userKey: string, membership): void {
    this.afs.doc(`users/${userKey}`).update({membership: membership});
  }

  get(userKey: string): Observable<User> {
    return this.db.docWithId$<User>(`users/${userKey}`);
  }

  delete(userKey: string): void {
    this.afs.doc(`users/${userKey}`).update({trash: true});
  }

  getReference(userKey: string): DocumentReference {
    return this.afs.doc(`users/${userKey}`).ref;
  }

  getCurrentUser(): Observable<User> {
    return this.db.docWithId$<User>(`users/${UserService.uid}`);
  }

  async getSpecificUser(userKey: string): Promise<User> {
    return await this.afs.doc<User>(`users/${userKey}`)
      .valueChanges()
      .pipe(take(1))
      .toPromise();
  }

  async uploadPicture(photo, videoKey: string, namePhoto: string) {
    const uploadRef = this.getStorageRef(videoKey, namePhoto);
    await uploadRef.put(photo);
    const url = await uploadRef.getDownloadURL().pipe(take(1)).toPromise();
    this.uploadImage(photo, videoKey, namePhoto);

    return url;
  }

  getStorageRef(id: string, photoName: string) {
    return this.storage.ref(`videos/${id}/${photoName}.jpeg`);
  }

  uploadImage(data, id, photoName: string) {
    return this.storage.upload(`videos/${id}/${photoName}.jpeg`, data);
  }

  async loadUser(userKey: string): Promise<any> {
    return new Promise<any>(resolve => {
      this.afs.doc<any>(`users/${userKey}`)
        .valueChanges()
        .subscribe(data => {

          if (!data) {
            resolve(null);
          }

          this.user = data;
          this.user.key = userKey;
          this.$user.next(data);
          resolve(data);
        });
    });
  }

  uploadPicturePrfile(photo: string, userKey: string) {
    return new Promise(resolve => {
      let imageRef = this.storage.ref(`users/${userKey}/userPhoto.jpeg`);
      imageRef.putString(photo, 'data_url')
        .then((snapshot) => {
          snapshot.ref.getDownloadURL().then(imageUrl => resolve(imageUrl));
        })
        .catch(err => console.log(err));
    });
  }

  hasActiveMembership() {
    if (!this.user) return false;

    return !!this.user
      && !!this.user.membership
      && this.user.membership.reference.id != 'y1pI4abvw2QWZGyM7v0F'
      && (this.user.membership.endTime == 95646466800000
        || this.user.membership.endTime == 32503705200000
        || this.user.membership.endTime == 1831060208000);
  }

  async deleteAccount() {
    if (this.hasActiveMembership()) {
      return this._alert.presentAlert(
        'No puedes eliminar tu cuenta',
        'Debes cancelar tu membresía para poder eliminar tu cuenta'
      );
    }

    if (await this._alert.confirm(
      '¿Estás seguro de eliminar tu cuenta?',
      'Se eliminarán todos tus datos, este cambio no podrá ser reversible',
      'Eliminar cuenta',
      'Cancelar'
    )) {
      await this.http.post(environment.apiBaseURL + '/deleteAccount', {uid: UserService.uid}).pipe(take(1)).toPromise();

      this._auth.signOut();
    }
  }
}
