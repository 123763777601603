import {Component, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {WelcomeSlideService} from '../../services/welcome-slide.service';
import {take} from 'rxjs/operators';
import * as _ from 'lodash';
import { Platform } from '@ionic/angular';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss'],
})
export class WelcomeComponent implements OnInit {
  @ViewChild('welcome', {static: true}) slides: any;
  isWelcome: boolean;
  welcomeSlides = [];
  isMobile: boolean;

  constructor(private router: Router,
              private plt: Platform,
              private _welcomeSlide: WelcomeSlideService) {
  }

  async ngOnInit() {
    await this.plt.ready();

    this.isMobile = this.plt.is('ios') || this.plt.is('android') || this.plt.is('mobileweb') || this.plt.is('mobile');
    this.welcomeSlides = _.orderBy(await this._welcomeSlide.getAll().pipe(take(1)).toPromise(), 'position', 'asc');
    console.log(this.welcomeSlides);
  }

  swipeNext() {
    this.slides.slideNext();
    if (this.isWelcome) this.router.navigateByUrl('auth/choose');
    this.isWelcome = this.slides.isEnd();
  }
}
