import {Component, OnInit} from '@angular/core';
import {UserService} from '../../../shared/services/user.service';
import {FriendService} from '../../services/friend.service';
import {take} from 'rxjs/operators';
import {AlertService} from '../../../shared/template-services/alert.service';
import {User} from '../../../shared/interfaces/user';
import {ModalController} from '@ionic/angular';

@Component({
  selector: 'app-add-friend',
  templateUrl: './add-friend.component.html',
  styleUrls: ['./add-friend.component.scss'],
})
export class AddFriendComponent implements OnInit {
  code: string = '';
  friendCode: string;
  myFriends: User[] = [];

  constructor(private _user: UserService,
              private _friend: FriendService,
              private modal: ModalController,
              private _alert: AlertService) {
  }

  ngOnInit() {
    if (!this._user.user.code) return this.generateCode();

    this.code = this._user.user.code;
  }

  async generateCode() {
    const code = Math.random().toString(36).substring(2, 8).toUpperCase();

    const usersWithSameCode = await this._friend.verifyUniqueCode(code).pipe(take(1)).toPromise();

    if (usersWithSameCode.length) return this.generateCode();

    this.code = code;
    this._user.update(UserService.uid, {code} as any);
  }

  goBack() {
    this.modal.dismiss();
  }

  async handleAddFriend() {
    if (!this.friendCode) return;
    if (this.friendCode.trim() == this.code) return this._alert.presentToast('No es posible agregarte a ti mismo');
    if (this.myFriends.some(myFriend => myFriend.code == this.friendCode.trim().toUpperCase())) return this._alert.presentToast('Este usuario ya está en tu lista de amigos');

    await this._friend.addFriend(this.friendCode.trim().toUpperCase());
    this._alert.presentToast('Amigo agregado con éxito!');
    this.friendCode = '';
    this.goBack();
  }

  handleCopyCode() {
    let textArea = document.createElement('textarea');
    textArea.value = this.code;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('Copy');
    textArea.remove();

    this._alert.presentToast('Código copiado con éxito!');
  }
}
