import {Component, OnInit} from '@angular/core';
import {User} from '../../../shared/interfaces/user';
import {FriendService} from '../../services/friend.service';
import {UserService} from '../../../shared/services/user.service';
import {ModalController} from '@ionic/angular';
import {AddFriendComponent} from '../../modals/add-friend/add-friend.component';
import {AlertService} from '../../../shared/template-services/alert.service';

@Component({
  selector: 'app-friends',
  templateUrl: './friends.component.html',
  styleUrls: ['./friends.component.scss'],
})
export class FriendsComponent implements OnInit {
  friends: User[] = [];
  filteredFriends: User[] = [];
  search: string = '';
  sharedVideo: any;

  constructor(private _friend: FriendService,
              public modalCtrl: ModalController,
              private _alert: AlertService) {
  }

  ngOnInit() {
    this._friend.getAllByUserId(UserService.uid).subscribe(async friends => {
      const usersRef = friends.map(friend => ({
        user: friend.users.find(user => user.id !== UserService.uid)
      }));

      this.filteredFriends = this.friends = (await this._friend.populate(usersRef, ['user'])).map(user => user.user);
    });
  }

  async handleAddFriend() {
    const modal = await this.modalCtrl.create({
      component: AddFriendComponent,
      componentProps: {
        myFriends: this.friends
      }
    });

    await modal.present();
  }

  filter() {
    this.filteredFriends = this.friends.filter(friend => (`${friend.name} ${friend.firstName} ${friend.lastName}`).toLowerCase().includes(this.search.toLowerCase()));
  }

  handleShareVideo(friend: User){
    this._friend.shareVideo(friend.key, this.sharedVideo.key);
    this._alert.presentToast(`Solicitud enviada a ${friend.firstName} ${friend.lastName}`);
    this.modalCtrl.dismiss()
  }
}
