import {Component, Input, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {VideoService} from '../../../shared/services/video.service';
import {Router} from '@angular/router';
import {CategoryService} from '../../../shared/services/category.service';
import {AngularFirestore} from '@angular/fire/firestore';
import {take} from 'rxjs/operators';
import {FavoriteService} from '../../../shared/services/favorite.service';
import {UserService} from '../../../shared/services/user.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnInit {
  @Input() isFavorite: boolean;
  categories: any[] = [];
  bodyParts: any[] = [];
  tools: any[] = [];
  durations: { value: string, selected: boolean }[] = [
    {value: '0 - 5', selected: false},
    {value: '5 - 10', selected: false},
    {value: '10 - 15', selected: false},
    {value: '15 - 30', selected: false},
    {value: '30 - 45', selected: false},
    {value: '45 - 60', selected: false},
    {value: '60 >', selected: false},
    {value: 'Todos', selected: false},
  ];
  title: string = '';
  difficulty: string = null;
  body = [
    'CORE/ABS AND GLUTES',
    'FULL BODY',
    'LOWER BODY',
    'QUICK ABS',
    'QUICK ARMS',
    'QUICK FULL BODY',
    'QUICK GLUTES',
    'QUICK LEGS',
    'UPPER BODY'
  ];
  isCategorySelected: boolean = true;
  isDifficultySelected: boolean = true;
  isDurationSelected: boolean = true;
  isToolSelected: boolean = true;
  isBodyPartsSelected: boolean = true;

  constructor(private modal: ModalController,
              private _video: VideoService,
              private router: Router,
              private _category: CategoryService,
              private afs: AngularFirestore,
              private _user: UserService,
              private _favorite: FavoriteService) {
  }

  async ngOnInit() {
    this._category.getAll().subscribe(data => {
      this.categories = data.map(category => ({...category, selected: false}));
      this.categories = this.categories.filter(category => category.program != 'challenge');
      this.bodyParts = this.categories.filter(category => this.body.some(part => part === category.name));
      this.categories = this.categories.filter(category => category.program == 'method');
    });

    this.tools = (await this._category.getAllTools().pipe(take(1)).toPromise()).map(tool => ({
      ...tool,
      selected: false
    }));
  }

  closeModal() {
    this.modal.dismiss();
  }

  filter() {
    let categories = [...(this.isBodyPartsSelected ? this.bodyParts : []), ...this.categories];

    categories = categories.filter(category => category.selected);
    let toolsSelected = this.tools.filter(tool => tool.selected);

    this._video.query = {
      categories: this.isCategorySelected ? categories : [],
      duration: this.durations.find(duration => duration.selected)?.value || null,
      title: this.title,
      tools: this.isToolSelected ? toolsSelected : [],
      difficulty: this.isDifficultySelected ? this.difficulty : null,
    };

    this._video.fetched = false;
    this._video.onSearchEmitter.next(true);

    if (!this.isFavorite) this.router.navigateByUrl('user/video-list');

    this.closeModal();
  }

  selectDuration(duration: string) {
    this.durations = this.durations.map(item => ({
      ...item,
      selected: item.value == duration
    }));
  }
}
