import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../../shared/template-services/auth.service';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { Platform } from '@ionic/angular';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  isMobile: boolean = true;
  year = new Date().getFullYear();

  constructor(
    public router: Router,
    public _auth: AuthService,
    private iab: InAppBrowser,
    private plt: Platform
  ) {
  }

  async ngOnInit() {
    await this.plt.ready();
    this.isMobile = this.plt.is('ios') || this.plt.is('android') || this.plt.is('mobileweb') || this.plt.is('mobile');
  }

  redirectToAppStore() {
    this.iab.create('https://apps.apple.com/mx/app/umana/id1550976756', '_system');
  }

  redirectToPlayStore() {
    this.iab.create('https://play.google.com/store/apps/details?id=com.umana.umanaapp', '_system');
  }
}
