import { Component, OnInit, ViewChild } from '@angular/core';
import firebase from 'firebase';
import User = firebase.User;
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../../shared/template-services/auth.service';
import { UserService } from '../../../shared/services/user.service';
import { Router } from '@angular/router';
import { Platform, ToastController } from '@ionic/angular';
import { AlertService } from '../../../shared/template-services/alert.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  isMobile: boolean = false;
  user: User;
  loginForm: FormGroup;
  formSubmitted: boolean = false;

  constructor(private _auth: AuthService,
              private _user: UserService,
              private router: Router,
              private formBuilder: FormBuilder,
              private toastController: ToastController,
              private plt: Platform,
              private _alert: AlertService) {
    this.createForm();
  }

  ngOnInit() {
    this.isMobile = this.plt.is('ios') || this.plt.is('android') || this.plt.is('mobileweb') || this.plt.is('mobile');
    const isLogin = localStorage.getItem('isLogin');
    if (isLogin === 'false') {
      localStorage.setItem('isLogin', 'true');
      window.location.reload();
    }
  }

  get formControls() {
    return this.loginForm.controls;
  }

  private createForm() {
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(8)]]
    });
  }

  async submit(isAnonymous: boolean = false): Promise<void> {
    this.formSubmitted = true;

    if (this.loginForm.valid || isAnonymous) {
      localStorage.setItem('isLogin', 'true');

      let email = isAnonymous ? 'guest@umana.com' : this.loginForm.get('email').value;
      let password = isAnonymous ? 'guestumana123' : this.loginForm.get('password').value;

      const user = await this._auth.loginWithPassword(email, password)
        .catch(err => {
          this._alert.presentAlert(AuthService.getMessageError(err.code), '');
        });

      if (!user) {
        return;
      }
      window.location.reload();
    }
  }

  async presentToast(error) {
    const toast = await this.toastController.create({
      message: `${error}`,
      duration: 2000
    });
    await toast.present();
  }

  async goToRegister() {
    await this.router.navigateByUrl('/auth/register');
  }

  async loginGuest() {


    // await this._auth.loginWest()
    // window.location.reload()
    // await this.router.navigateByUrl('/user');
  }

  goToLogin() {
    this.router.navigateByUrl('auth/choose');
  }

  goToResetPassword() {
    this.router.navigateByUrl('auth/reset-password');
  }
}
