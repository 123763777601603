import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {first, take} from 'rxjs/operators';
import {WorkoutSerie} from '../../interfaces/workout-serie';
import {WorkoutSerieService} from '../../services/workout-serie.service';
import {VideoService} from '../../../shared/services/video.service';
import {DocumentReference} from '@angular/fire/firestore';
import {Video} from '../../../shared/interfaces/video';
import {ActionSheetController, ModalController, Platform} from '@ionic/angular';
import {VideoPlayerComponent} from '../../modals/video-player/video-player.component';
import {UserService} from '../../../shared/services/user.service';
import {FavoriteService} from '../../../shared/services/favorite.service';
import {Favorite} from '../../../shared/interfaces/favorite';
import {AlertService} from '../../../shared/template-services/alert.service';
import {CategoryService} from '../../../shared/services/category.service';
import {VideoHistoryService} from '../../../shared/services/video-history.service';
import * as _ from 'lodash';
import {ProgramsService} from '../../services/programs.service';
import {SettingService} from '../../../shared/services/setting.service';
import {Location} from '@angular/common';
import {AuthService} from '../../../shared/template-services/auth.service';
import {InAppBrowser} from '@ionic-native/in-app-browser/ngx';
import {MembershipService} from '../../../shared/services/membership.service';
import {CommentsComponent} from '../../modals/comments/comments.component';
import {UsersSeenCurrentVideoComponent} from '../../modals/users-seen-current-video/users-seen-current-video.component';
import {FriendsComponent} from '../friends/friends.component';

@Component({
  selector: 'app-workout-serie-details',
  templateUrl: './workout-serie-details.component.html',
  styleUrls: ['./workout-serie-details.component.scss'],
})
export class WorkoutSerieDetailsComponent implements OnInit {
  workoutSerieKey: string;
  workoutSerie: WorkoutSerie = {} as WorkoutSerie;
  myCurrentWorkoutSerie: any;
  loading: boolean;
  myFavoritesVideos: Favorite[] = [];
  section: string;
  challengeSection: any;
  calendarSection: any;
  program: any = {};
  memberships: any[] = [];
  isDesktop: boolean;

  constructor(private activatedRoute: ActivatedRoute,
              private _workoutSerie: WorkoutSerieService,
              private router: Router,
              private _video: VideoService,
              public modalController: ModalController,
              public actionSheetController: ActionSheetController,
              private _favorite: FavoriteService,
              private _alert: AlertService,
              private _category: CategoryService,
              private _history: VideoHistoryService,
              private _program: ProgramsService,
              private _settings: SettingService,
              private location: Location,
              private _user: UserService,
              private _auth: AuthService,
              private iab: InAppBrowser,
              private platform: Platform,
              private _membership: MembershipService) {
  }

  async ngOnInit() {
    await this.platform.ready();
    this.isDesktop = this.platform.is('desktop');
    this.loading = true;
    await this.loadSettings();

    this.memberships = await this._membership.getAll().pipe(take(1)).toPromise();

    this.section = this.activatedRoute.snapshot.paramMap.get('section');
    switch (this.section) {
      case 'workout-series':
        this.workoutSerieKey = this.activatedRoute.snapshot.paramMap.get('workoutSerieKey');
        if (!this.workoutSerieKey) {
          this.router.navigate(['user/home']);
        }
        await this.loadWorkoutSerie();
        this.workoutSerie.videos = await this.getFormattedVideos(
          <DocumentReference[]> this.workoutSerie.videos
        );
        await this.loadMyCurrentWorkoutSerie();
        break;

      case 'challenges':
        if (!this._video.query.categories.length) return this.router.navigate(['user/home']);
        this.workoutSerie = {
          title: this._video.query.categories[0].name,
          videos: _.orderBy(await this._video.getAll().pipe(first()).toPromise(), 'createdAt', 'desc'),
          photoUrl: this.challengeSection.imageUrl
        } as WorkoutSerie;
        break;

      case 'calendar':
        if (!this._video.query.categories.length) return this.router.navigate(['user/home']);
        this.workoutSerie = {
          title: this._video.query.categories[0].name,
          videos: _.orderBy(await this._video.getAll().pipe(first()).toPromise(), 'createdAt', 'desc'),
          photoUrl: this.calendarSection.imageUrl
        } as WorkoutSerie;
        break;

      case 'category':
        if (!this._video.query.categories.length) return this.router.navigate(['user/home']);
        this.workoutSerie = {
          title: this._video.query.categories[0].name,
          videos: _.orderBy(this._video.query.categories[0].videos, 'createdAt', 'desc'),
          photoUrl: 'assets/images/image00004-min.jpeg'
        } as WorkoutSerie;
        break;

      case 'programs':
        const programName = this.activatedRoute.snapshot.paramMap.get('workoutSerieKey');
        let videosCategories = await this._video.getAllWell();

        if (!this._program.programs.length) {
          this._program.programs = (await this._program.getAll().pipe(first()).toPromise())
            .filter((program: any) => !program.trash);
        }

        const program = this.program = this._program.programs.find(program => program.code === programName);
        const photoUrl = !!program.coverImageUrl ? program.coverImageUrl : '';

        let categories: any = await this._category.getAll().pipe(first()).toPromise();
        let history: any = await this._history.getAll().pipe(first()).toPromise();

        this.workoutSerie = {
          title: program.name,
          photoUrl,
          color: program.color,
          videos: _.orderBy(
            _.uniqBy(
              categories
                .map(category => ({
                  ...category,
                  videos: videosCategories
                    .filter(video =>
                      video.categories.find(videoCategory => videoCategory.id == category.key)
                    )
                    .map(video => ({
                      ...video,
                      isSeen: history.some(history => history.reference.id == video.key)
                    }))
                }))
                .filter(category => category.program == programName)
                .map(category => category.videos)
                .flat(),
              'key'
            )
            , 'createdAt', 'desc')
        } as any;
        break;
    }
    await this.loadMyFavoritesVideos();
    this.loading = false;
  }

  async loadWorkoutSerie() {
    this.workoutSerie = await this._workoutSerie
      .get(this.workoutSerieKey)
      .pipe(first())
      .toPromise();
  }

  async loadMyFavoritesVideos() {
    this.myFavoritesVideos = await this._favorite
      .getAllMyFavorites(UserService.uid)
      .pipe(first())
      .toPromise();
  }

  async loadMyCurrentWorkoutSerie() {
    this.myCurrentWorkoutSerie = await this._workoutSerie
      .getMyCurrentWorkoutSerie(UserService.uid, this.workoutSerieKey)
      .pipe(first())
      .toPromise();

    if (!this.myCurrentWorkoutSerie) return;
    if (this.myCurrentWorkoutSerie.videos.length) this.checkSeenVideos();
    if (!this.myCurrentWorkoutSerie.isCompleted) await this.checkIfAllVideosAreSeen();
  }

  async checkIfAllVideosAreSeen() {
    if (this.section != 'workout-series') return;
    if (this.myCurrentWorkoutSerie.videos.every(video => video.isSeen)) {
      this.myCurrentWorkoutSerie.isCompleted = true;
      await this._workoutSerie.updateMyWorkoutSerie(UserService.uid, this.workoutSerieKey, {isCompleted: true});
      this._alert.presentToast('¡Felicidades! Has completado este reto');
    }
  }

  checkSeenVideos() {
    if (this.section != 'workout-series') return;
    this.workoutSerie.videos = (<any[]> this.workoutSerie.videos).map((video) => {
      const myCurrentVideo = this.myCurrentWorkoutSerie.videos.find(videoItem => videoItem.video.id === video.key);

      return {
        ...video,
        isSeen: !!myCurrentVideo && !!myCurrentVideo.isSeen,
        isFavorite: this.myFavoritesVideos.some(favorite => favorite.reference.id === video.key)
      };
    });
  }

  async getFormattedVideos(videos: DocumentReference[]) {
    if (!videos.length) {
      return [];
    }
    return await Promise.all(
      videos.map(async (video) => await this._video.get(video.id).pipe(first()).toPromise())
    );
  }

  async signOutGuest() {
    if (!await this._auth.isAuthenticated()) {
      if (await this._alert.confirm('Esta es una función para usuarios registrados', 'Iniciar sesión', 'Cancelar')) {
        localStorage.setItem('isLogin', 'false');
        this.router.navigateByUrl('auth');
        this.modalController.dismiss();
      }
      return;
    }
  }

  hasFreeTrialMembership(membershipKey: string): boolean {
    if (membershipKey == 'y1pI4abvw2QWZGyM7v0F') return true;

    const membership = this.memberships.find(membership => membership.key == membershipKey);

    return !!membership && membership.isFree;
  }

  async openVideoFullScreen(video: Video) {
    if (this.hasFreeTrialMembership(this._user.user.membership.reference.id)) {
      let membership = video.memberships.findIndex(membership => membership.id == this._user.user.membership.reference.id);
      if (membership == -1 || this._user.user.membership.status == 0) {
        return this._alert.presentAlert('No cuentas con la membresía para ver este video', '');
      }
    }

    if (!await this._auth.isAuthenticated()) {
      this.signOutGuest();
    }

    if (this.section != 'workout-series') {
      this._history.set({
        reference: this._video.getReference(video.key),
        lastView: new Date().getTime(),
        currentTime: 0
      });
    }
    await this.checkIsFirstTimeOpeningVideos();

    const modal = await this.modalController.create({
      component: VideoPlayerComponent,
      componentProps: {
        video: {...video},
        workoutSerie: this.workoutSerie,
        myCurrentWorkoutSerie: this.myCurrentWorkoutSerie,
        isWorkoutSeries: this.section == 'workout-series'
      },
      cssClass: 'full-screen-video-modal',
      swipeToClose: true,
    });

    await modal.present();
    await modal.onWillDismiss();
    this.section == 'workout-series' && await this.loadMyCurrentWorkoutSerie();
  }

  decorateVideosWorkoutSerieForUser() {
    return (<Video[]> this.workoutSerie.videos).map(video => ({
      video: this._video.getReference(video.key),
      lastView: 0,
      currentTime: 0,
      isSeen: false
    }));
  }

  async checkIsFirstTimeOpeningVideos() {
    if (!(!!this.myCurrentWorkoutSerie && this.myCurrentWorkoutSerie.videos.some(({video}) => this.workoutSerie.videos.some((currentVideo) => currentVideo.key === video.id)))) {
      this.myCurrentWorkoutSerie = {
        workoutSerie: this._workoutSerie.getReference(this.workoutSerieKey),
        videos: this.decorateVideosWorkoutSerieForUser(),
        isCompleted: false,
        createdAt: new Date().getTime(),
        updatedAt: new Date().getTime()
      };
      await this._workoutSerie.setInUser(UserService.uid, this.workoutSerieKey, this.myCurrentWorkoutSerie);
    }
  }

  goToWorkoutSeries() {
    this.location.back();
  }

  async presentActionSheet(video: Video | any) {
    const actionSheet = await this.actionSheetController.create({
      header: 'Opciones',
      mode: 'md',
      buttons: [
        {
          text: video.isFavorite ? 'Eliminar de favoritos' : 'Agregar a favoritos',
          icon: video.isFavorite ? 'heart-outline' : 'heart',
          handler: async () => {
            await this.toggleFavorite(video);
          }
        },
        this.section == 'workout-series'
          ? {
            text: video.isSeen ? 'Marcar video como no visto' : 'Marcar video como visto',
            icon: video.isSeen ? 'checkmark' : 'checkmark-done',
            handler: async () => {
              await this.toggleIsSeen(video);
            }
          }
          : {}
      ]
    });
    await actionSheet.present();
  }

  async toggleIsSeen(currentVideo: Video) {
    await this.checkIsFirstTimeOpeningVideos();

    this.myCurrentWorkoutSerie.videos = this.myCurrentWorkoutSerie.videos.map((videoItem) => {
      if (videoItem.video.id !== currentVideo.key) return videoItem;
      return {
        ...videoItem,
        isSeen: !videoItem.isSeen
      };
    });

    this.workoutSerie.videos = (<any[]> this.workoutSerie.videos).map((videoItem) => {
      if (videoItem.key !== currentVideo.key) return videoItem;
      return {
        ...videoItem,
        isSeen: !videoItem.isSeen
      };
    });

    await this._workoutSerie.updateMyWorkoutSerie(UserService.uid, this.workoutSerieKey, this.myCurrentWorkoutSerie);

    if (!this.myCurrentWorkoutSerie.isCompleted) await this.checkIfAllVideosAreSeen();
  }

  async toggleFavorite(video: Video) {
    const myCurrentFavorite: Favorite = this.myFavoritesVideos.find(favorite => favorite.reference.id === video.key);

    !!myCurrentFavorite
      ? await this._favorite.delete(UserService.uid, myCurrentFavorite.key)
      : await this._favorite.add(UserService.uid, {
        reference: this._video.getReference(video.key),
        title: video.title,
        trash: false,
      });

    this.workoutSerie.videos = (<any[]> this.workoutSerie.videos).map((videoItem) => {
      if (videoItem.key !== video.key) return videoItem;
      return {
        ...videoItem,
        isFavorite: !videoItem.isFavorite
      };
    });

    await this.loadMyFavoritesVideos();

    await this._alert.presentToast(video.isFavorite ? 'Se eliminó de favoritos' : 'Se agregó a favoritos');
  }

  loadSettings() {
    this._settings.getAll().pipe(first()).subscribe(data => {
      const indexedSettings = _.keyBy(data, 'key');
      this.calendarSection = indexedSettings['calendar'];
      this.challengeSection = indexedSettings['challenge'];
    });
  }

  async downloadMaterial() {
    if (this.hasMembershipToDownloadMaterial()) return this.iab.create(this.program.welcomeFile, '_blank');

    this._alert.presentAlert('No cuentas con la membresía para descargar este material', '');
  }

  private hasMembershipToDownloadMaterial() {
    return !!this._user.user.membership
      && !!this._user.user.membership.reference
      && this._user.user.membership.reference.id != 'GkocmIhtAIzjCdAyrLtO'
      && this._user.user.membership.reference.id != '5gTBi9dlOszgfGLjsji3'
      && this._user.user.membership.reference.id != 'y1pI4abvw2QWZGyM7v0F';
  }

  async handleVideoOptions(video: Video) {
    const actionSheet = await this.actionSheetController.create({
      buttons: [
        {
          text: 'Reproducir video',
          handler: async () => {
            this.openVideoFullScreen(video);
          }
        },
        {
          text: 'Interactuar',
          handler: async () => {
            await this.openCommentsModal(video);
          }
        },
        {
          text: 'Usuarios que vieron esta clase',
          handler: async () => {
            await this.openUserList(video);
          }
        },
        {
          text: 'Compartir con un amigo',
          handler: async () => {
            await this.handleOpenFriendList(video);
          },
        }
      ]
    });
    await actionSheet.present();
  }

  async handleOpenFriendList(video) {
    const modal = await this.modalController.create({
      component: FriendsComponent,
      componentProps: {
        sharedVideo: video
      }
    });

    await modal.present();
  }

  async openCommentsModal(video: Video) {
    const modal = await this.modalController.create({
      component: CommentsComponent,
      componentProps: {
        video: video
      }
    });

    await modal.present();
  }

  async openUserList(video: Video) {
    const modal = await this.modalController.create({
      component: UsersSeenCurrentVideoComponent,
      componentProps: {
        video: video
      }
    });

    await modal.present();
  }
}
