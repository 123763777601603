import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {User} from '../../../shared/interfaces/user';
import {ModalController} from '@ionic/angular';
import {AuthService} from '../../../shared/template-services/auth.service';
import {UserService} from '../../../shared/services/user.service';
import firebase from 'firebase';
import FirebaseError = firebase.FirebaseError;
import {AlertService} from '../../../shared/template-services/alert.service';

@Component({
  selector: 'app-change-email',
  templateUrl: './change-email.component.html',
  styleUrls: ['./change-email.component.scss'],
})
export class ChangeEmailComponent implements OnInit {
  changeEmailForm: FormGroup;
  formSubmitted: boolean = false;
  user: User;

  constructor(private formBuilder: FormBuilder,
              private _auth: AuthService,
              private _user: UserService,
              public modalController: ModalController,
              private _alert: AlertService) {
    this.createForm();
  }

  async ngOnInit() {
    await this.changeEmailForm.patchValue({currentEmail: this.user.email});
  }

  get formControls() {
    return this.changeEmailForm.controls;
  }

  createForm(): void {
    this.changeEmailForm = this.formBuilder.group({
      currentEmail: ['', Validators.required],
      newEmail: ['', Validators.required],
      currentPassword: ['', Validators.required],
    });
  }

  async changeEmail() {
    this.formSubmitted = true;
    if (this.changeEmailForm.valid) {
      const userCredential = await this._auth.loginWithPassword(this.changeEmailForm.value.currentEmail, this.changeEmailForm.value.currentPassword)
        .catch((err: FirebaseError) => {
          this._alert.presentToast(`Error al insertar su correo electrónico actual, ${AuthService.getMessageError(err.code)}`);
        });
      if (userCredential == undefined) {
        return;
      }

      if (userCredential) {
        userCredential.user.updateEmail(this.changeEmailForm.value.newEmail).then(() => {
          this._user.update(this._auth.user.uid, {email: this.changeEmailForm.value.newEmail} as User);
          this._alert.presentToast(`Se ha cambiado el email con éxito, ahora es ${this.changeEmailForm.value.newEmail}`);
          this.dismissModal();
        }).catch((err: FirebaseError) => {
          this._alert.presentToast(`Error al insertar su nuevo correo electrónico, ${AuthService.getMessageError(err.code)}`);
        });
      }
    } else {
      await this._alert.presentToast(`Favor de llenar todos los campos`);
    }
  }

  dismissModal() {
    this.modalController.dismiss().then(r => r = null);
  }
}
