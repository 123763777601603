import {Component, OnInit} from '@angular/core';
import {NotificationPushService} from '../shared/services/notification-push.service';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss'],
})
export class UserComponent implements OnInit {

  constructor(private _notification: NotificationPushService) {
  }

  async ngOnInit() {
    await this._notification.getToken();
    this._notification.listenNotifications();
  }

}
