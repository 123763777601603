import {Injectable} from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {UserService} from '../../shared/services/user.service';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  constructor(private afs: AngularFirestore,
              private _user: UserService) {
  }

  async createCard(cardToken: string): Promise<any> {
    return await this.afs.collection(`users/${UserService.uid}/createCard`).add({
      cardToken,
      uid: UserService.uid,
      name: !!this._user.user.name ? this._user.user.name : '',
      phone: !!this._user.user.phoneNumber ? this._user.user.phoneNumber : '',
      email: '',
      customerId: !!this._user.user.customerId ? this._user.user.customerId : null
    });
  }

  static getMessageError(code: string): string {
    switch (code) {
      case 'Your card number is incorrect.':
        return 'El número de tu tarjeta es incorrecta';

      case 'Your card\'s expiration month is invalid.':
        return 'El mes ingresado es inválido';

      case 'Your card\'s expiration year is invalid.':
        return 'El año ingresado es inválido';

      case 'Your card\'s security code is invalid.':
        return 'El CVC es inválido';

      case 'Value  at expMonth of type java.lang.String cannot be converted to int':
        return 'Verifique sus datos';

      case 'Value  at expYear of type java.lang.String cannot be converted to int':
        return 'Verifique sus datos';

      case 'Value  at cvc of type java.lang.String cannot be converted to int':
        return 'Verifique sus datos';

      case 'Value  at number of type java.lang.String cannot be converted to int':
        return 'Verifique sus datos';

      default:
        return code;
    }
  }

  async deleteCard(): Promise<any> {
    return await this.afs.collection(`users/${UserService.uid}/deleteCard`).add({
      customerId: this._user.user.customerId
    });
  }
}
