import { Component, OnDestroy, OnInit } from '@angular/core';
import { VideoHistoryService } from '../../../shared/services/video-history.service';
import * as moment from 'moment';
import { debounceTime, take } from 'rxjs/operators';
import * as _ from 'lodash';
import { CalendarService } from '../../services/calendar.service';
import { Calendar } from '../../interfaces/calendar';
import { UserService } from '../../../shared/services/user.service';
import { ProgramsService } from '../../services/programs.service';
import { HistoryService } from '../../services/history.service';
import { CategoryService } from '../../../shared/services/category.service';
import { VideoService } from '../../../shared/services/video.service';
import { Platform } from '@ionic/angular';
import { Subscription } from "rxjs";

@Component({
  selector: 'app-activity',
  templateUrl: './activity.component.html',
  styleUrls: ['./activity.component.scss']
})
export class ActivityComponent implements OnInit, OnDestroy {
  days: {
    day: Date,
    isCurrentDay: boolean,
    hasActivity: boolean,
    isAfterFromCurrentDay: boolean,
    isCurrentMonth: boolean
  }[] = [];
  weeks: any[] = [];
  weekDays: string[] = ['D', 'L', 'M', 'M', 'J', 'V', 'S'];
  selectedMonth: Date = new Date();
  videoHistory: any;
  moment = moment;
  dayChecked: any;
  feelingChecked: boolean = false;
  loaded: boolean = false;
  finishDays: boolean[] = [];
  challenges: boolean[] = [];
  totalCalendars: number = 0;
  finishDayPercentage: number = 0;
  challengesPercentage: number = 0;
  programs;
  history;
  videosCategories;
  categories;
  myFeeling: any = [];
  consecutiveRepeatFeeling: any = [];
  firstFeeling;
  isMobile = false;
  videoHistorySubscription: Subscription = new Subscription();

  constructor(
    private _videoHistory: VideoHistoryService,
    private _program: ProgramsService,
    public _calendar: CalendarService,
    private _category: CategoryService,
    private _video: VideoService,
    private _history: VideoHistoryService,
    private plt: Platform
  ) {
  }

  async ngOnInit() {
    await this.plt.ready();

    this.isMobile = this.plt.is('ios') || this.plt.is('android') || this.plt.is('mobileweb') || this.plt.is('mobile');

    this.videoHistorySubscription = this._videoHistory.getAll().subscribe(async videoHistory => {
      const data = videoHistory.map(item => ({
        ...item,
        lastView: moment(item.lastView).format('YYYY-MM-DD')
      }));

      this.videoHistory = _.groupBy(data, 'lastView');

      this.generateDays();

      const dataCalendar = await this._calendar.getAll().pipe(take(1)).toPromise();
      const calendars = _.orderBy(dataCalendar, ['createdAt'], ['asc']) as Calendar[];

      const currentCalendar = (calendars.find(c => moment().isSame(c.date, 'day')) || calendars[0]);
      const myFeeling = await this._calendar.getMyFeeling(currentCalendar.key, UserService.uid).pipe(take(1)).toPromise();

      this.feelingChecked = myFeeling.length > 0;

      const currentVideos = currentCalendar.videos.map(video => ({
        video,
        watched: !!this.videoHistory[moment().format('YYYY-MM-DD')]?.find(videoHistory => videoHistory.key == video.id)
      }));

      this.dayChecked = currentVideos.every(video => video.watched);

      this.calculateDays(calendars);
      this.totalCalendars = calendars.length;
      this.finishDayPercentage = this.getDayCheckedPercentage();
      await this.loadPrograms();

      this.myFeeling = _.orderBy(await this._calendar.getAllMyFeeling(UserService.uid).pipe(take(1)).toPromise(), 'createdAt', 'desc');
      this.firstFeeling = this.myFeeling[0];

      for (const feeling of this.myFeeling) {
        if (this.firstFeeling.feelingRanking == feeling.feelingRanking) {
          this.consecutiveRepeatFeeling.push(feeling);
        } else {
          break;
        }
      }

      this.loaded = true;
    });
  }

  ngOnDestroy() {
    this.videoHistorySubscription.unsubscribe();
  }

  calculateDays(calendars) {
    this.finishDays = calendars.map(calendar => {
      const currentVideos = calendar.videos.map(video => ({
        video,
        watched: !!this.videoHistory[moment().format('YYYY-MM-DD')]?.find(videoHistory => videoHistory.key == video.id)
      }));

      return currentVideos.every(video => video.watched);
    });
  }

  getDayCheckedPercentage() {
    return this.finishDays.filter(day => day).length / this.totalCalendars * 100;
  }

  async loadPrograms() {
    const allPrograms = (await this._program.getAll().pipe(take(1)).toPromise());

    const filteredPrograms = allPrograms.filter((program: any) => !program.trash && this.verifyPrincipalProgram(program));

    this.programs = _.orderBy(filteredPrograms, 'position', 'asc');

    this.history = await this._history.getAll().pipe(debounceTime(1000), take(1)).toPromise();

    this.videosCategories = (await this._video.getAllWell())
      .map(video => ({
        ...video,
        isHistory: this.history.some(history => history?.reference?.id == video.key)
      }));

    this.categories = await this._category.getAll().pipe(take(1)).toPromise();

    this.categories = this.categories.map(category => ({
      ...category,
      videos: this.videosCategories.filter(video => video.categories.find(videoCategory => videoCategory.id == category.key))
    }));

    const totalChallenges = _.flatMap(this.categories.filter(category => category.program == 'challenge'), 'videos');
    this.challenges = totalChallenges.filter(video => video.isHistory);
    this.challengesPercentage = this.challenges.length / totalChallenges.length * 100;

    this.programs = this.programs
      .map(program => ({
        ...program,
        videos: _.flatMap(this.categories.filter(category => category.program == program.code), 'videos')
      }))
      .map(program => ({
        ...program,
        watchedVideos: program.videos.filter(video => video.isHistory).length,
        totalVideos: program.videos.length,
        percentage: program.videos.filter(video => video.isHistory).length / program.videos.length * 100
      }));
  }

  private verifyPrincipalProgram(program: any) {
    return program.code != 'calendar'
      && program.code != 'challenge'
      && program.code != 'Sin Programa';
  }

  generateDays() {
    const days = [];
    const startMonth = moment(this.selectedMonth).startOf('month');
    const endMonth = moment(this.selectedMonth).endOf('month');

    for (let i = startMonth.date(); i <= endMonth.date(); i++) {
      days.push(moment(this.selectedMonth).date(i).toDate());
    }

    const startMonthDay = moment(this.selectedMonth).startOf('month').day();

    for (let i = 0; i < startMonthDay; i++) {
      days.unshift(moment(this.selectedMonth).date(-i).toDate());
    }

    this.days = days.map(day => ({
      day,
      isCurrentDay: moment(day).isSame(moment(), 'day'),
      isCurrentMonth: moment(day).isSame(moment(), 'month'),
      isAfterFromCurrentDay: moment(day).isAfter(moment(), 'day'),
      hasActivity: !!this.videoHistory[moment(day).format('YYYY-MM-DD')]
    }));

    const chunksWeek = _.chunk(this.days, 7);

    this.weeks = chunksWeek.map(week => ({
      hasActivity: week.some(day => day.hasActivity)
    }));
  }

  nextMonth() {
    this.selectedMonth = moment(this.selectedMonth).add(1, 'month').toDate();
    this.generateDays();
  }

  prevMonth() {
    this.selectedMonth = moment(this.selectedMonth).subtract(1, 'month').toDate();
    this.generateDays();
  }
}
