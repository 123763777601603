import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {User} from '../../../shared/interfaces/user';
import {ModalController} from '@ionic/angular';
import {AuthService} from '../../../shared/template-services/auth.service';
import firebase from 'firebase';
import FirebaseError = firebase.FirebaseError;
import {AlertService} from '../../../shared/template-services/alert.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent implements OnInit {
  changePasswordForm: FormGroup;
  formSubmitted: boolean = false;
  user: User;

  constructor(private formBuilder: FormBuilder,
              private _auth: AuthService,
              public modalController: ModalController,
              private _alert: AlertService) {
    this.createForm();
  }

  ngOnInit() {
  }

  get formControls() {
    return this.changePasswordForm.controls;
  }

  createForm(): void {
    this.changePasswordForm = this.formBuilder.group({
      currentPassword: ['', Validators.required],
      newPassword: ['', Validators.required],
      newPasswordRepeat: ['', Validators.required],
    });
  }

  async changePassword() {
    this.formSubmitted = true;
    if (this.changePasswordForm.value.newPassword != this.changePasswordForm.value.newPasswordRepeat) return this._alert.presentToast('Las contraseñas no coinciden');
    if (this.changePasswordForm.valid) {
      const userCredential = await this._auth.loginWithPassword(this.user.email, this.changePasswordForm.value.currentPassword)
        .catch((err: FirebaseError) => {
          this._alert.presentToast(`Error al insertar su contraseña actual, ${AuthService.getMessageError(err.code)}`);
        });
      if (userCredential == undefined) {
        return;
      }
      if (userCredential) {
        userCredential.user.updatePassword(this.changePasswordForm.value.newPassword).then(() => {
          this._alert.presentToast('Se ha cambiado la contraseña con éxito')
          this.dismissModal();
        }).catch((err: FirebaseError) => {
          this._alert.presentToast(`Error al insertar su contraseña actual, ${AuthService.getMessageError(err.code)}`);
        });
      }
    }
  }

  dismissModal() {
    this.modalController.dismiss().then(r => r = null);
  }
}
