import { Injectable } from '@angular/core';
import {FirebaseDataService} from '../template-services/firebase-data.service';
import {Category} from '../interfaces/category';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ChallengeService {

  constructor(private db: FirebaseDataService) { }

  getAll(): Observable<Category[]>{
    return this.db.colWithIds$<Category>('challengeCategories', ref => ref
      .where('trash', '==', false))
  }

  getMonthChallenge(): Observable<any>{
    return this.db.colWithIds$<any>('monthChallenges', ref => ref
      .where('trash','==', false))
  }
}
