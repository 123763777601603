import {Component, OnInit} from '@angular/core';
import {first, take} from 'rxjs/operators';
import {ProgramsService} from '../../services/programs.service';
import {Router} from '@angular/router';
import * as _ from 'lodash';
import {SettingService} from '../../../shared/services/setting.service';
import {Platform} from '@ionic/angular';

@Component({
  selector: 'app-programs',
  templateUrl: './programs.page.html',
  styleUrls: ['./programs.page.scss'],
})
export class ProgramsPage implements OnInit {
  programs: any = [];
  programsSection = {
    title: '',
    titleColor: '',
    imageUrl: '',
    imageUrlWeb: '',
    bubbleColor: ''
  };
  isWeb: boolean;

  constructor(private _program: ProgramsService,
              private router: Router,
              private platform: Platform,
              private _settings: SettingService) {
  }

  async ngOnInit() {
    const filteredPrograms = (await this._program.getAll().pipe(first()).toPromise())
      .filter((program: any) => !program.trash && this.verifyPrincipalProgram(program) && program.isActive == true);

    this.programs = _.orderBy(filteredPrograms, 'position', 'asc');
    this._program.programs = this.programs;

    this.loadSettings();

    await this.platform.ready();
    this.isWeb = this.platform.is('desktop');
  }

  private verifyPrincipalProgram(program: any) {
    return program.code != 'calendar'
      && program.code != 'challenge'
      && program.code != 'Sin Programa';
  }

  goToProgram(programCode) {
    return this.router.navigateByUrl('user/all-videos' + '?program=' + programCode);
  }

  loadSettings() {
    this._settings.getAll().pipe(take(1)).subscribe(data => {
      const indexedSettings:any = _.keyBy(data, 'key');
      this.programsSection = indexedSettings['program'];
    });
  }
}
