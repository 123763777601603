import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {CompletedGoal, UserAchievement} from '../../../interfaces/achievement';
import {ActionSheetController} from '@ionic/angular';
import {SocialSharing} from '@ionic-native/social-sharing/ngx';
import {UserService} from '../../../../shared/services/user.service';

@Component({
  selector: 'app-achievement',
  templateUrl: './achievement.component.html',
  styleUrls: ['./achievement.component.scss'],
})
export class AchievementComponent implements OnInit {
  @Input() achievement: UserAchievement;
  @Input() completedGoal: CompletedGoal;

  constructor(private actionSheetCtrl: ActionSheetController,
              private socialSharing: SocialSharing,
              private _user: UserService) {
  }

  ngOnInit() {
  }

  async handleAchievementClick() {
    const actionSheet = await this.actionSheetCtrl.create({
      buttons: [
        {
          text: 'Compartir',
          handler: () => {
            this.socialSharing.share(`${this._user.user.firstName || ''} ${this._user.user.lastName || ''} ha desbloqueado un logro por ver ${this.completedGoal.goal} videos de ${this.achievement.ref.name}`, 'Logro en Umana App', this.achievement.ref.icons[this.completedGoal.goal], `https://umana.com.mx`);
          }
        },
        {
          text: 'Cancelar',
          role: 'cancel'
        },
      ],
    });

    await actionSheet.present();
  }

}
