import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { DocPipe } from './pipes/doc.pipe';
import { SafeDomainPipe } from './pipes/safe-domain.pipe';
import { FilterPipe } from './pipes/filter.pipe';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { OrderbyPipe } from './pipes/orderby.pipe';
import { DurationPipe } from './pipes/duration.pipe';
import { RecipeModalComponent } from '../user/pages/calendar-v2/components/recipe-modal/recipe-modal.component';
import { HeaderComponent } from '../user/components/header/header.component';
import { FooterComponent } from '../user/components/footer/footer.component';
import { IonicModule } from '@ionic/angular';

@NgModule({
  declarations: [
    DocPipe,
    SafeDomainPipe,
    FilterPipe,
    NotFoundComponent,
    OrderbyPipe,
    DurationPipe,
    RecipeModalComponent,
    HeaderComponent,
    FooterComponent
  ],
  exports: [
    DocPipe,
    SafeDomainPipe,
    FilterPipe,
    NotFoundComponent,
    OrderbyPipe,
    DurationPipe,
    RecipeModalComponent,
    HeaderComponent,
    FooterComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule
  ]
})

export class SharedModule {
  static forRoot() {
    return {
      ngModule: SharedModule
    };
  }
}
