import { Component, OnDestroy, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { Video } from '../../../shared/interfaces/video';
import { Subscription } from 'rxjs';
import { VideoService } from '../../../shared/services/video.service';
import { IonContent, ModalController, Platform } from '@ionic/angular';
import { SearchComponent } from '../../modals/search/search.component';
import { AuthService } from '../../../shared/template-services/auth.service';
import { AlertService } from '../../../shared/template-services/alert.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CategoryService } from '../../../shared/services/category.service';
import { UserService } from '../../../shared/services/user.service';
import { Category } from '../../../shared/interfaces/category';
import { first, take } from 'rxjs/operators';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { MembershipService } from '../../../shared/services/membership.service';
import * as moment from 'moment';
import { FavoriteService } from '../../../shared/services/favorite.service';
import { VideoHistoryService } from '../../../shared/services/video-history.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { User } from '../../../shared/interfaces/user';
import { ProgramsService } from '../../services/programs.service';
import * as _ from 'lodash';
import { SettingService } from '../../../shared/services/setting.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  animations: [
    trigger('panelState', [
      state('closed', style({ height: '43px', overflow: 'hidden' })),
      state('open', style({ height: '*' })),
      transition('closed <=> open', animate('300ms ease-in-out'))
    ])
  ]
})
export class HomeComponent implements OnInit, OnDestroy {
  @ViewChild('slideWithNav', { static: false })
  @ViewChild(IonContent, { static: false }) content: IonContent;
  @ViewChildren('categories') categoriesView;
  isMobile = true;

  sliderNewVideos = {
    initialSlide: 0,
    autoplay: true,
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      clickable: false
    }
  };
  sliderPresentNewVideos: any = {
    isBeginningSlide: true,
    isEndSlide: false
  };
  videos: Video[] = [];
  videosSubscription: Subscription = new Subscription();
  videosCarouselSubscription: Subscription = new Subscription();
  videosCategoriesSubscription: Subscription = new Subscription();
  isUser: boolean = false;
  userSubscribe: Subscription = new Subscription();
  isWeb: boolean = false;
  videosCarousel: Video[] = [];
  videoCarousel: Video;
  categories: Category[];
  categoriesOrigin: Category[];
  videosCategories: Video[];
  memberships;
  favorites = [];
  history = [];
  programs = [];
  workoutSection = {
    title: '',
    titleColor: '',
    imageUrl: '',
    bubbleColor: ''
  };
  calendarSection = {
    title: '',
    titleColor: '',
    imageUrl: '',
    bubbleColor: ''
  };
  challengeSection = {
    title: '',
    titleColor: '',
    imageUrl: '',
    bubbleColor: ''
  };
  wellnessSection = {
    title: '',
    titleColor: '',
    imageUrl: '',
    bubbleColor: ''
  };
  videosSection = {
    title: '',
    titleColor: '',
    imageUrl: '',
    bubbleColor: ''
  };
  programSection = {
    title: '',
    titleColor: '',
    imageUrl: '',
    bubbleColor: ''
  };
  mealPlansSections = {
    title: '',
    titleColor: '',
    imageUrl: '',
    bubbleColor: ''
  };
  riderSection = {
    title: '',
    titleColor: '',
    imageUrl: '',
    bubbleColor: ''
  };
  videoUrl = '';
  isLoading: boolean = true;

  constructor(private _video: VideoService,
              private platform: Platform,
              private modal: ModalController,
              private _auth: AuthService,
              private _alert: AlertService,
              public router: Router,
              private iab: InAppBrowser,
              private _category: CategoryService,
              private _membership: MembershipService,
              public modalController: ModalController,
              private _history: VideoHistoryService,
              private _favorite: FavoriteService,
              private _user: UserService,
              private plt: Platform,
              private _program: ProgramsService,
              private activatedRoute: ActivatedRoute,
              private _settings: SettingService) {
  }

  async ngOnInit() {
    await this.plt.ready();

    this.isMobile = this.plt.is('ios') || this.plt.is('android') || this.plt.is('mobileweb') || this.plt.is('mobile');

    this.activatedRoute.url.subscribe(data => {
      setTimeout(() => {
        if (!this.content) return;

        this.content.scrollToPoint(0, +sessionStorage.getItem('scroll'), 0);

        this.categoriesView.forEach(category => {
          category.slideTo(+sessionStorage.getItem(category.el.id));
        });
      }, 100);
    });

    this.isWeb = this.plt.is('desktop');

    const filteredPrograms = (await this._program.getAll().pipe(take(1)).toPromise())
      .filter((program: any) => !program.trash && this.verifyPrincipalProgram(program) && program.isActive == true);

    this.programs = _.orderBy(filteredPrograms, 'position', 'asc');
    this._program.programs = this.programs;

    await this._auth.getCurrentUser();

    this.history = await this._history.getAll().pipe(take(1)).toPromise();
    this.favorites = await this._favorite.getAllByUser(this._auth.user.uid).pipe(take(1)).toPromise();

    this.memberships = await this._membership.getAll().pipe(take(1)).toPromise();

    this.userSubscribe = this._user.getCurrentUser().subscribe(async data => {
      this._user.user = data;
    });

    let userResp: any = await this._user.getCurrentUser().pipe(take(1)).toPromise();

    if (!!userResp
      && userResp.membership.reference.id != 'y1pI4abvw2QWZGyM7v0F'
      && (userResp.membership.endTime == 95646466800000
        || userResp.membership.endTime == 32503705200000
        || userResp.membership.endTime == 1831060208000)) {
      this._membership.getStatus(userResp.subscription).then(resp => {
        if (resp.ok && resp.status == 'canceled') {
          this._user.update(this._user.user.key, {
            membership: {
              ...this._user.user.membership,
              endTime: resp.endTime
            }
          } as User);
        }
      });
    }

    this.videosCategories = (await this._video.getAllWell())
      .map(video => ({
        ...video,
        isNew: moment(new Date()).diff(video.createdAt, 'day') <= 5,
        isFavorite: this.favorites.some(favorite => favorite.reference.id == video.key),
        isHistory: this.history.some(history => history.reference.id == video.key)
      }));

    this.categories = await this._category.getAll().pipe(take(1)).toPromise();

    this.categories = this.categories.map(category => ({
      ...category,
      videos: this.videosCategories.filter(video => video.categories.find(videoCategory => videoCategory.id == category.key))
    }));

    this.categoriesOrigin = [...this.categories];
    this.categories = this.categoriesOrigin.filter(category => category.program == 'method');

    this.videosCarouselSubscription = this._video.getAllCarousel()
      .subscribe(data => {
        this.videosCarousel = data;
        setTimeout(() => {
          let i = 0;
          setInterval(() => {
            this.videoCarousel = this.videosCarousel[i];
            i = (i + 1) % this.videosCarousel.length;
          }, 5000);
        }, 5000);
      });

    this.loadSettings();
  }

  loadSettings() {
    this._settings.getAll().pipe(take(1)).subscribe(data => {
      const indexedSettings: any = _.keyBy(data, 'key');
      this.workoutSection = indexedSettings['workoutSeries'];
      this.calendarSection = indexedSettings['calendar'];
      this.challengeSection = indexedSettings['challenge'];
      this.wellnessSection = indexedSettings['wellness'];
      this.videosSection = indexedSettings['videos'];
      this.programSection = indexedSettings['program'];
      this.mealPlansSections = indexedSettings['meal-plans'];
      this.riderSection = indexedSettings['rider'];
      this.videoUrl = indexedSettings['setting'].videoUrl;

      this.isLoading = false;
    });
  }

  async goToUrl(video) {
    const url = video.url;

    if (url) {
      if (!this.plt.is('cordova')) {
        return window.open(url, '_blank');
      } else {
        return this.iab.create(url, '_system');
      }
    }

    if (video.insideUrl) {
      if (video.redirectVideo) {
        this._video.currentVideo = await this._video.get(video.redirectVideo).pipe(first()).toPromise();
        return this.router.navigateByUrl(`user/video-detail`);
      }

      if (video.redirectProgram) {
        const program: any = await this._program.get(video.redirectProgram).pipe(first()).toPromise();
        return this.router.navigateByUrl(`user/all-videos?program=${program.code}`);
      }

      this.router.navigateByUrl(`/user/${video.insideUrl}`);
    }
  }

  ngOnDestroy() {
    this.videosCarouselSubscription.unsubscribe();
    this.videosSubscription.unsubscribe();
    this.userSubscribe.unsubscribe();
    this.videosCategoriesSubscription.unsubscribe();
  }

  async openSearch() {
    const modal: any = await this.modal.create({
      component: SearchComponent
    });

    modal.present();
  }

  async goToVideoDetail(video: Video) {
    if (!await this._auth.isAuthenticated()) {
      this.isUser = true;
      this.signOutGuest();
    }
    this._video.currentVideo = video;
    await this.router.navigateByUrl(`user/video-detail`);
  }

  async signOutGuest() {
    if (!await this._auth.isAuthenticated()) {
      if (await this._alert.confirm('Esta es una función para usuarios registrados', 'Iniciar sesión', 'Cancelar')) {
        localStorage.setItem('isLogin', 'false');
        this.router.navigateByUrl('auth');
        this.modalController.dismiss();
      }
      return;
    }
  }

  goToVideos() {
    this.router.navigateByUrl('user/videos');
  }

  goToPrograms() {
    this.router.navigateByUrl('user/programs');
  }

  goToWorkout() {
    this.router.navigateByUrl('user/workout-series');
  }

  goToCalendar() {
    this.router.navigateByUrl('user/calendar');
  }

  goToChallenge() {
    this.router.navigateByUrl('user/challenges');
  }

  goToRider() {
    this.router.navigateByUrl('user/all-videos?program=rider');
  }

  goToWellness() {
    this.router.navigateByUrl('user/recipes');
  }

  slideDidChange(slider, slideView) {
    this.checkIfNavDisable(slider, slideView);
  }

  checkIfNavDisable(slider, slideView) {
    this.checkIsNewVideos(slider, slideView);
    this.chekIsEnd(slider, slideView);
  }

  checkIsNewVideos(slider, slideView) {
    slideView.isBeginning().then((isTrue) => {
      slider.isBeginningSlide = isTrue;
    });
  }

  chekIsEnd(slider, slideView) {
    slideView.isEnd().then((isTrue) => {
      slider.isEndSlide = isTrue;
    });
  }

  private verifyPrincipalProgram(program: any) {
    return program.code != 'calendar'
      && program.code != 'challenge'
      && program.code != 'Sin Programa';
  }

  handleScroll(e) {
    sessionStorage.setItem('scroll', e.detail.scrollTop);
  }

  goToMealPlans() {
    this.router.navigateByUrl('user/meal-plans');
  }

  goToTutorial() {
    this.iab.create(this.videoUrl, '_blank');
  }
}
