import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'orderBy'
})
export class OrderbyPipe implements PipeTransform {

  transform(array: any[], property: string, order: string): any {
    if (order == 'asc') {
      return array.sort((a, b) => {
        if (a[property].toString().toLowerCase() < b[property].toString().toLowerCase()) {
          return -1;
        } else if (a[property].toString().toLowerCase() > b[property].toString().toLowerCase()) {
          return 1;
        } else {
          return 0;
        }
      });
    }
  }

}
