import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ObjectService {

  constructor() {
  }

  static getJSONArray(json): any[] {
    let array = [];

    for (let i in json) {
      if (i != 'default') array.push(json[i]);
    }

    return array;
  }

  static disablePointer(itemToDisablePointer: any): any {
    return JSON.parse(JSON.stringify(itemToDisablePointer));
  }

  static convertToURL(text: string): string {
    return text.replace(/ /g, '%20');
  }

  static urlToText(url: string): string {
    return url.replace(/%20/g, ' ');
  }

  static indexArray(array, propertyIndex?: string) {
    if (!!propertyIndex) {
      return array.reduce((acc, el) => ({
        ...acc,
        [el[propertyIndex]]: el
      }), {});
    } else {
      return array.reduce((acc, el) => ({
        ...acc,
        [el]: el
      }), {});
    }
  }
}
