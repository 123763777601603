import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { VideoService } from '../../../shared/services/video.service';
import { Video } from '../../../shared/interfaces/video';
import { Subscription } from 'rxjs';
import { Category } from '../../../shared/interfaces/category';
import { AuthService } from '../../../shared/template-services/auth.service';
import { AlertService } from '../../../shared/template-services/alert.service';
import { ModalController, Platform } from '@ionic/angular';
import { UserService } from '../../../shared/services/user.service';
import * as _ from 'lodash';
import { take } from 'rxjs/operators';
import { CategoryService } from '../../../shared/services/category.service';

@Component({
  selector: 'app-category-list',
  templateUrl: './category-list.component.html',
  styleUrls: ['./category-list.component.scss']
})
export class CategoryListComponent implements OnInit, OnDestroy {
  categories: Category[] = [];
  videos: Video[];
  allVideos: Video[];
  videosSubscription: Subscription = new Subscription();
  categoriesSubscription: Subscription = new Subscription();
  isUser: boolean = false;
  userSubscribe: Subscription = new Subscription();
  calendar: any;
  search: string = '';
  filteredVideos: Video[] = [];
  timeout: any = null;
  limit: number = 20;
  maxLimit: number = 0;
  isMobile: boolean = true;
  categorySelected: any;
  unmappedCategories: any;
  searchEmitterSubscription: Subscription = new Subscription();
  isSearchApplied: boolean = false;

  constructor(
    public _video: VideoService,
    private router: Router,
    private _alert: AlertService,
    private plt: Platform,
    public modalController: ModalController,
    private _auth: AuthService,
    private _user: UserService,
    private _category: CategoryService
  ) {
  }

  async ngOnInit() {
    await this.plt.ready();
    this.isMobile = this.plt.is('ios') || this.plt.is('android') || this.plt.is('mobileweb') || this.plt.is('mobile');

    await this._auth.getCurrentUser();
    this.userSubscribe = this._user.getCurrentUser().subscribe(data => {
      this._user.user = data;
    });

    this.unmappedCategories = (await this._category.getAll().pipe(take(1)).toPromise());

    this.loadVideos();

    this.searchEmitterSubscription = this._video.onSearchEmitter.subscribe(isSearch => {
      if (isSearch) {
        this.videos = [];
        this.allVideos = [];
        this.filteredVideos = [];
        this._video.videos$.next([]);
        this._video.categorySelected = null;

        this.loadVideos(true);
        this.isSearchApplied = true;
      }
    });
  }

  loadVideos(refresh: boolean = false) {
    this.videosSubscription.unsubscribe();

    if (
      !refresh &&
      (this._video.query.categories.length > 0 ||
        this._video.query.tools.length > 0 ||
        this._video.query.title ||
        this._video.query.difficulty ||
        this._video.query.duration != 'Todos')
    ) {
      this.isSearchApplied = true;
    }

    this.videosSubscription = this._video.getByBehaviorSubject(refresh || this.isSearchApplied).subscribe(videos => {
      this.allVideos = _.orderBy(videos, ['createdAt'], ['desc']).filter(video => video.duration);

      this.maxLimit = this.allVideos.length;
      this.filteredVideos = this.allVideos.slice(0, this.limit);
      this.videos = this.allVideos;

      this.categories = _.cloneDeep(this.unmappedCategories)
        .map(category => ({
          ...category,
          videos: this.allVideos.filter(video => video.categories.find(videoCategory => videoCategory.id == category.key))
        }))
        .filter(category => category.program == 'method');

      if (this._video.categorySelected) {
        this.categorySelected = this._video.categorySelected;
        this.filterVideos();
      }
    });

    this._video.query = {
      categories: [],
      duration: 'Todos',
      title: '',
      tools: [],
      difficulty: null
    };
  }

  ngOnDestroy() {
    this.videosSubscription.unsubscribe();
    this.categoriesSubscription.unsubscribe();
    this.userSubscribe.unsubscribe();
    this.searchEmitterSubscription.unsubscribe();
  }

  async goToVideoDetail(video: Video) {
    if (!await this._auth.isAuthenticated()) {
      this.isUser = true;
      this.signOutGuest();
    }
    this._video.currentVideo = video;
    await this.router.navigateByUrl(`user/video-detail`);
  }

  async signOutGuest() {
    if (!await this._auth.isAuthenticated()) {
      if (await this._alert.confirm('Esta es una función para usuarios registrados', 'Iniciar sesión', 'Cancelar')) {
        localStorage.setItem('isLogin', 'false');
        this.router.navigateByUrl('auth');
        this.modalController.dismiss();
      }
      return;
    }
  }

  filter() {
    clearTimeout(this.timeout);

    this.timeout = setTimeout(() => {
      this.filteredVideos = this.videos.filter(video => (video.title).toLowerCase().includes(this.search.toLowerCase())).slice(0, this.limit);
    }, 1000);
  }

  loadMore() {
    this.limit += 20;

    this.filteredVideos = this.videos.filter(video => (video.title).toLowerCase().includes(this.search.toLowerCase())).slice(0, this.limit);

    if (this.limit > this.filteredVideos.length) {
      this._alert.presentToast('No hay más videos para mostrar');
    }
  }

  filterVideos(category?: Category) {
    this._video.categorySelected = category?.key || this.categorySelected;

    if (this.isSearchApplied && category) {
      return this.resetVideoList();
    }

    this.limit = 20;

    if (category || this.categorySelected) {
      this.videos = category?.videos || this.categories.find(c => c.key == this.categorySelected).videos;
      this.filteredVideos = this.videos.slice(0, this.limit);
    } else {
      this.videos = this.allVideos;
      this.filteredVideos = this.videos.slice(0, this.limit);
    }
  }

  resetVideoList() {
    if (this.isSearchApplied) {
      this.videos = [];
      this.allVideos = [];
      this.filteredVideos = [];
      this._video.videos$.next([]);

      this.loadVideos(true);
      this.isSearchApplied = false;
      return;
    }

    this._video.categorySelected = null;
    this.categorySelected = null;
    this.videos = this.allVideos;

    this.filteredVideos = this.allVideos.slice(0, this.limit);
  }

  redirectToVideos(category?: Category) {
    this._video.allVideos = [];
    this._video.fetched = false;
    this._video.videos$.next([]);

    if (category) {
      this._video.categorySelected = category.key;
      this.router.navigateByUrl(`user/video-list/${category.key}`);
    } else {
      this.router.navigateByUrl(`user/video-list/all`);
    }
  }
}
