import { Injectable } from '@angular/core';
import { FirebaseDataService } from '../../shared/template-services/firebase-data.service';

@Injectable({
  providedIn: 'root'
})
export class MealPlanService {

  constructor(private db: FirebaseDataService) {
  }

  getAll() {
    return this.db.colWithIds$('meal-plans', ref => ref
      .where('trash', '==', false)
      .orderBy('createdAt', 'desc'));
  }
}
