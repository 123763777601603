import {Component, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {User} from '../../../shared/interfaces/user';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {UserService} from '../../../shared/services/user.service';
import {Camera} from '@ionic-native/camera/ngx';
import {AlertService} from '../../../shared/template-services/alert.service';

@Component({
  selector: 'app-update-profile',
  templateUrl: './update-profile.component.html',
  styleUrls: ['./update-profile.component.scss'],
})
export class UpdateProfileComponent implements OnInit {
  user: User = {key: ''} as User;
  userForm: FormGroup;
  formSubmitted: boolean = false;
  path;
  countries: any[] = [];
  states: any[] = [];
  cities: any[] = [];
  headers = new Headers();
  requestOptions: any;
  isEditLocation: boolean = false;

  constructor(public modalController: ModalController,
              private formBuilder: FormBuilder,
              private _user: UserService,
              private camera: Camera,
              private _alert: AlertService) {
    this.createForm();
    this.headers.append('X-CSCAPI-KEY', 'U3dWbVRzRmNGcFBhMWZOUUg2bEk4NmJkTzVzR3FrQmJXUm9CbUdwaA==');
    this.requestOptions = {
      method: 'GET',
      headers: this.headers,
      redirect: 'follow'
    };
  }

  async ngOnInit() {
    await this.getCountries();
    this.loadUserInformation();
  }

  getCountries() {
    return new Promise((resolve) => {
      fetch('https://api.countrystatecity.in/v1/countries', this.requestOptions)
        .then(response => response.text())
        .then(result => {
          this.countries = [
            {
              name: 'México',
              iso2: 'MX'
            },
            {
              name: 'United States',
              iso2: 'US'
            },
            ...JSON.parse(result)
          ];
          resolve();
        })
        .catch(error => console.log('error', error));
    });
  }

  getStates(country) {
    fetch(`https://api.countrystatecity.in/v1/countries/${country}/states`, this.requestOptions)
      .then(response => response.text())
      .then(result => this.states = JSON.parse(result))
      .catch(error => console.log('error', error));
  }

  getCities(state) {
    fetch(`https://api.countrystatecity.in/v1/countries/${this.userForm.value.country}/states/${state}/cities`, this.requestOptions)
      .then(response => response.text())
      .then(result => this.cities = JSON.parse(result))
      .catch(error => console.log('error', error));
  }

  onCountryChange(event) {
    this.getStates(event.target.value);
  }

  onStateChange(event) {
    this.getCities(event.target.value);
  }

  loadUserInformation() {
    this._user.getCurrentUser().subscribe(user => {
      this.user = user;
      this.userForm.patchValue({
        ...user,
        birthdate: new Date(user.birthDate).toISOString(),
        country: user.country ? this.countries.find(country => country.name === user.country).iso2 : '',
      });
    });
  }

  get formControls() {
    return this.userForm.controls;
  }

  createForm(): void {
    this.userForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      gender: ['', Validators.required],
      birthDate: ['', Validators.required],
      country: ['', Validators.required],
      state: ['', Validators.required],
      city: ['', Validators.required],
      trash: [false],
    });
  }

  async submit() {
    this.formSubmitted = true;

    if (this.userForm.valid) {
      await this._user.update(this.user.key, {
        ...this.userForm.value,
        birthdate: new Date(this.userForm.value.birthdate).getTime(),
        country: this.userForm.value.country ? this.countries.find(country => country.iso2 === this.userForm.value.country || country.name === this.userForm.value.country).name : '',
        state: this.userForm.value.state && this.states.length ? this.states.find(state => state.iso2 === this.userForm.value.state || state.name === this.userForm.value.state).name : ''
      });

      this.dismissModal();
    }
  }

  dismissModal() {
    this.modalController.dismiss().then(r => r = null);
  }

  startEditLocation() {
    this.userForm.patchValue({
      country: '',
      state: '',
      city: '',
    })
    this.isEditLocation = !this.isEditLocation
  }
}
