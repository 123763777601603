import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {WorkoutSerie} from '../interfaces/workout-serie';
import {FirebaseDataService} from '../../shared/template-services/firebase-data.service';

@Injectable({
  providedIn: 'root'
})
export class WorkoutSerieService {

  constructor(private db: FirebaseDataService) {
  }

  getAll(): Observable<WorkoutSerie[]> {
    return this.db.colWithIds$('workoutSeries', ref => ref.where('trash', '==', false));
  }

  get(workoutSerieKey: string): Observable<WorkoutSerie> {
    return this.db.docWithId$(`workoutSeries/${workoutSerieKey}`);
  }

  setInUser(userKey: string, workoutSerieKey: string, workoutSerie): Promise<void> {
    return this.db.doc(`users/${userKey}/WorkoutSeriesHistory/${workoutSerieKey}`).set({...workoutSerie}, {merge: true});
  }

  getMyCurrentWorkoutSerie(userKey: string, workoutSerieKey: string): Observable<any> {
    return this.db.docWithId$(`users/${userKey}/WorkoutSeriesHistory/${workoutSerieKey}`);
  }

  updateMyWorkoutSerie(userKey: string, workoutSerieKey: string, workoutSerie: any): Promise<void> {
    return this.db.doc(`users/${userKey}/WorkoutSeriesHistory/${workoutSerieKey}`).update({...workoutSerie});
  }

  getReference(workoutSerieKey: string) {
    return this.db.doc(`workoutSeries/${workoutSerieKey}`).ref;
  }
}
