import {Injectable} from '@angular/core';
import {FirebaseDataService} from 'src/app/shared/template-services/firebase-data.service';
import {AngularFirestore} from '@angular/fire/firestore';
import {ReplaySubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProgramsService {
  private fetched: boolean;
  private value$: ReplaySubject<any> = new ReplaySubject();
  programs: any[] = [];

  constructor(private db: FirebaseDataService,
              private afs: AngularFirestore) {
  }

  getAll() {
    if (!this.fetched) {
      this.db.colWithIds$('programs', ref => ref
        .orderBy('trash', 'asc'))
        .subscribe(data => {
          this.fetched = true;
          this.value$.next(data);
        });
    }

    return this.value$;
  }

  update(programKey: string, program) {
    this.afs.doc(`programs/${programKey}`).update(program);
  }

  add(programKey: string, program) {
    this.afs.doc(`programs/${programKey}`).set(program);
  }

  get(programKey: string) {
    return this.db.doc$(`programs/${programKey}`);
  }
}
