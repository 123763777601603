import {Component} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../../../shared/template-services/auth.service';
import {UserService} from '../../../shared/services/user.service';
import {Router} from '@angular/router';
import { Platform, ToastController } from '@ionic/angular';
import firebase from 'firebase';
import User = firebase.User;
import {MembershipService} from '../../../shared/services/membership.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent {
  isMobile: boolean = false;
  user: User;
  registerForm: FormGroup;
  formSubmitted: boolean = false;
  userId: string;

  constructor(private _auth: AuthService,
              private _user: UserService,
              private router: Router,
              private formBuilder: FormBuilder,
              private toastController: ToastController,
              private plt: Platform,
              private _membership: MembershipService) {
    this.createForm();
  }

  ngOnInit() {
    this.isMobile = this.plt.is('ios') || this.plt.is('android') || this.plt.is('mobileweb') || this.plt.is('mobile');
  }

  get formControls() {
    return this.registerForm.controls;
  }

  private createForm() {
    let membershipReference = this._membership.getReference('y1pI4abvw2QWZGyM7v0F');
    this.registerForm = this.formBuilder.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(8)]],
      birthDate: [null],
      pendingMembership: [null],
      membership: [{
        endTime: 32503705200000,
        reference: membershipReference,
        startTime: 1616050800000
      }],
      membershipKey: [null],
      photoUrl: [null],
      token: [''],
      createdAt: [new Date().getTime()],
      trash: [false],
    });
  }

  async submit(): Promise<void> {
    this.formSubmitted = true;
    if (this.registerForm.valid) {
      this._auth.createAccount(this.registerForm.value.email, this.registerForm.value.password)
        .then(async data => {
          delete this.registerForm.value['password'];
          await this._user.addUserByKey(data.user.uid, this.registerForm.value);
          this.userId = data.user.uid;
          window.location.reload();
        })
        .catch(err => {
          this.presentToast(err.code);
        });
    }
  }

  async presentToast(error) {
    const toast = await this.toastController.create({
      message: `${error}`,
      duration: 2000
    });
    await toast.present();
  }

  async goToLogin() {
    await this.router.navigateByUrl('/auth/choose');
  }
}
