import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {AlertController, IonSlides, LoadingController, ModalController} from '@ionic/angular';
import {UserService} from '../../../shared/services/user.service';
import {User} from '../../../shared/interfaces/user';
import {PaymentService} from '../../services/payment.service';
import {Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {AngularFirestore} from '@angular/fire/firestore';
import {Membership} from '../../../shared/interfaces/membership';
import {AddCardPaymentComponent} from '../add-card-payment/add-card-payment.component';
import {AlertService} from '../../../shared/template-services/alert.service';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss'],
})
export class PaymentComponent implements OnInit, OnDestroy {
  @Input() membership: Membership;
  currentUser: User;
  cards: any[] = [];
  cardsSubscription: Subscription = new Subscription();
  loading: boolean = false;

  constructor(public modalCtrl: ModalController,
              private _payment: PaymentService,
              private router: Router,
              private loadingCtrl: LoadingController,
              private _user: UserService,
              private afs: AngularFirestore,
              private alertController: AlertController,
              private _alert: AlertService) {
  }

  async ngOnInit() {
    this.currentUser = this._user.user;
    this.getCards();
  }

  ngOnDestroy() {
    this.cardsSubscription.unsubscribe();
  }

  async openAddCardModal() {
    const modal = await this.modalCtrl.create({
      component: AddCardPaymentComponent
    });
    await modal.present();
    await modal.onWillDismiss();

    this.getCards();

    this.loadingCtrl.dismiss();
  }

  async getCards(): Promise<any> {
    await this.presentLoading('Cargando tarjetas');
    this.cardsSubscription.unsubscribe()
    this.cardsSubscription = this.afs.collection(`users/${UserService.uid}/cards`)
      .valueChanges()
      .subscribe(cards => {
        this.cards = cards;
        this.loadingCtrl.dismiss();
      });
  }

  async requestOrder(): Promise<void> {
    const alert = await this.alertController.create({
      header: `¿Estás seguro de que quieres realizar la contratación de la membresía $${this.membership.name}?`,
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
          handler: ()=>{
          }
        },
        {
          text: 'Confirmar',
          handler: async () => {
            await this.presentLoading('Realizando compra, podría demorar unos minutos');
            const purchaseDoc = await this.afs.collection(`users/${UserService.uid}/purchases`).add({
              customerId: this._user.user.customerId,
              amount: this.membership.price * 100
            });

            this.afs.doc(`users/${UserService.uid}/purchases/${purchaseDoc.id}`)
              .valueChanges()
              .subscribe((resp: any) => {
                if (!!resp.ok) {
                  this.loadingCtrl.dismiss();
                  this.modalCtrl.dismiss(true);
                } else if (!!resp.err) {
                  this.loadingCtrl.dismiss();
                  this._alert.presentAlert(resp.err.raw.message,'');
                  this.modalCtrl.dismiss(false);
                }
              });
          }
        }
      ]
    });

    await alert.present()
  }

  async presentLoading(message: string): Promise<void> {
    const loading = await this.loadingCtrl.create({
      message,
      spinner: 'circular'
    });
    return await loading.present();
  }
}
