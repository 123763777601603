import {Injectable} from '@angular/core';
import {FirebaseDataService} from '../template-services/firebase-data.service';
import {AngularFirestore} from '@angular/fire/firestore';
import {Observable} from 'rxjs';
import {Commentary} from '../interfaces/commentary';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CommentService {

  constructor(private db: FirebaseDataService,
              private afs: AngularFirestore) {
  }

  getAll(): Observable<Commentary[]> {
    return this.afs.collectionGroup<Commentary>(`comments`, ref => ref
      .where('trash', '==', false))
      .snapshotChanges()
      .pipe(map(actions => actions.map(a => {
        return {
          ...a.payload.doc.data(),
          key: a.payload.doc.id
        }
      })))
  }

  getAllByVideo(videoKey: string): Observable<Commentary[]> {
    return this.afs.collection<Commentary>(`videos/${videoKey}/comments`, ref => ref
      .where('trash', '==', false)
      .orderBy('createdAt', 'desc'))
      .snapshotChanges()
      .pipe(map(actions => actions.map(a => {
        return {
          ...a.payload.doc.data(),
          key: a.payload.doc.id
        }
      })))
  }

  async add(videoKey: any, comment: any): Promise<any> {
    return await this.afs.collection<Commentary>(`videos/${videoKey}/comments`).add(comment);
  }

  async delete(videoKey: string, commentKey: string): Promise<void> {
    return await this.afs.doc<Commentary>(`videos/${videoKey}/comments/${commentKey}`).update({trash: true});
  }

  update(videoKey: any, commentKey: any, comment: any) {
    this.afs.doc(`videos/${videoKey}/comments/${commentKey}`).update(comment);
  }

  get(videoKey: string, commentKey: string) {
    return this.afs.doc(`videos/${videoKey}/comments/${commentKey}`).valueChanges();
  }
}
