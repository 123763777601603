import {Injectable} from '@angular/core';
import {FirebaseDataService} from '../../shared/template-services/firebase-data.service';
import {AngularFirestore} from '@angular/fire/firestore';
import {Observable} from 'rxjs';
import {Friend} from '../interfaces/friend';
import {take} from 'rxjs/operators';
import {AlertService} from '../../shared/template-services/alert.service';
import {UserService} from '../../shared/services/user.service';

@Injectable({
  providedIn: 'root'
})
export class FriendService extends FirebaseDataService {

  constructor(private _db: AngularFirestore,
              private _alert: AlertService) {
    super(_db);
  }

  getAllByUserId(userId: string): Observable<Friend[]> {
    return this.colWithIds$<Friend>('friends', ref => ref
      .where('users', 'array-contains', this.getReference(`users/${userId}`))
      .where('trash', '==', false)
    );
  }

  verifyUniqueCode(code: string): Observable<Friend[]> {
    return this.colWithIds$<Friend>('friends', ref => ref
      .where('code', '==', code)
    );
  }

  async addFriend(code: string) {
    const friend: any = await this.colWithIds$('users', ref => ref.where('code', '==', code)).pipe(take(1)).toPromise();

    if (!friend.length) {
      this._alert.presentToast('No se encontró ningún usuario con ese código');
      throw new Error('No se encontró ningún amigo con ese código');
    }

    this._db.collection('friends').add({
      users: [this.getReference(`users/${UserService.uid}`), this.getReference(`users/${friend[0].key}`)],
      createdAt: new Date().getTime(),
      trash: false
    });
  }

  getRequests() {
    return this.addedColWithIds$(`users/${UserService.uid}/requests`, ref => ref.where('trash', '==', false));
  }

  removeRequest(requestKey: string) {
    return this._db.doc(`users/${UserService.uid}/requests/${requestKey}`).update({trash: true});
  }

  shareVideo(friendKey: string, videoKey: string) {
    return this._db.collection(`users/${friendKey}/requests`).add({
      friend: this.getReference(`users/${UserService.uid}`),
      video: this.getReference(`videos/${videoKey}`),
      createdAt: new Date().getTime(),
      trash: false
    });
  }
}
