import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Video} from '../../../shared/interfaces/video';
import {Favorite} from '../../../shared/interfaces/favorite';
import {AuthService} from '../../../shared/template-services/auth.service';
import {UserService} from '../../../shared/services/user.service';
import {VideoService} from '../../../shared/services/video.service';
import {AlertService} from '../../../shared/template-services/alert.service';
import {FavoriteService} from '../../../shared/services/favorite.service';
import {take} from 'rxjs/operators';
import {Observable, Subscription} from 'rxjs';
import {FormBuilder} from '@angular/forms';
import {DomSanitizer} from '@angular/platform-browser';
import {VideoHistoryService} from '../../../shared/services/video-history.service';
import {Router} from '@angular/router';
import {CategoryService} from '../../../shared/services/category.service';
import {Category} from '../../../shared/interfaces/category';
import {FirebaseDataService} from '../../../shared/template-services/firebase-data.service';
import {ActionSheetController, LoadingController, ModalController, Platform} from '@ionic/angular';
import {Location} from '@angular/common';
import {AchievementService} from '../../services/achievement.service';
import {Achievement} from '../../interfaces/achievement';
import {ProgramsService} from '../../services/programs.service';
import {ObjectService} from '../../../shared/services/object.service';
import {CommentsComponent} from '../../modals/comments/comments.component';
import {UsersSeenCurrentVideoComponent} from '../../modals/users-seen-current-video/users-seen-current-video.component';
import {FriendsComponent} from '../friends/friends.component';
import {SocialSharing} from '@ionic-native/social-sharing/ngx';

@Component({
  selector: 'app-video-detail',
  templateUrl: './video-detail.component.html',
  styleUrls: ['./video-detail.component.scss'],
})
export class VideoDetailComponent implements OnInit, OnDestroy {
  video: Video;
  favorites$: Observable<Favorite[]>;
  favorites: Favorite[];
  relatedVideosSubscription: Subscription = new Subscription();
  @ViewChild('video1') videoPlayer: any;
  isInitialReproduction: boolean = true;
  relatedVideos: Video[] = [];
  categories: Category[] = [];
  isShowVideo: boolean = false;
  timeInterval;
  calendar: string;
  favorite: string;
  challenge: string;
  category: string;
  videoHistory: any;
  isWeb: boolean;

  constructor(private _auth: AuthService,
              private _user: UserService,
              private _video: VideoService,
              private _alert: AlertService,
              private _favorite: FavoriteService,
              private _categories: CategoryService,
              private platform: Platform,
              private _program: ProgramsService,
              private router: Router,
              private sanitizer: DomSanitizer,
              private formBuilder: FormBuilder,
              private _videoHistory: VideoHistoryService,
              private db: FirebaseDataService,
              private _category: CategoryService,
              private loadingCtrl: LoadingController,
              private location: Location,
              private _achievement: AchievementService,
              public actionSheetController: ActionSheetController,
              private socialSharing: SocialSharing,
              public modalController: ModalController) {
  }

  async ngOnInit() {
    await this.platform.ready();
    this.isWeb = this.platform.is('desktop');

    clearInterval(this.timeInterval);
    this.video = this._video.currentVideo || JSON.parse(sessionStorage.getItem('video')) as any;

    sessionStorage.setItem('video', JSON.stringify(this.video));

    this.videoHistory = await this._videoHistory.get(this.video.key).pipe(take(1)).toPromise();

    await this.getFavorites();
    this.categories = await this._categories.getAll().pipe(take(1)).toPromise();

    this.loadRelatedVideos();
    this.saveVideoTime();
  }

  ngOnDestroy() {
    if (!this.video || !this.isShowVideo) return;

    this._videoHistory.set({
      reference: this._video.getReference(this.video.key),
      lastView: new Date().getTime(),
      currentTime: this.videoPlayer.nativeElement.currentTime
    });

    this.relatedVideosSubscription.unsubscribe();
    clearInterval(this.timeInterval);
    this.verifyAchievements();
  }

  async verifyAchievements() {
    if (!this.verifyCompletedVideo()) return;

    const programs: any = ObjectService.indexArray(await this._program.getAll().pipe(take(1)).toPromise(), 'code');

    for (const category of this.video.categories) {
      const categoryData = this.categories.find(c => c.key === category.id);
      const achievement: Achievement = await this._achievement.getAchievementByRef(categoryData.program == 'method'
        ? category
        : this._achievement.getReference(`programs/${programs[categoryData.program].key}`));

      if (!achievement) continue;
      if (!achievement.visible) continue;

      return this._achievement.handleCompletedGoal(achievement, this.video);
    }
  }

  verifyCompletedVideo() {
    if (!this.videoPlayer && !this.videoHistory) return false;

    return ((this.videoPlayer
      ? this.videoPlayer.nativeElement.currentTime
      : this.videoHistory.currentTime) / this.video.duration) * 100 >= 90;
  }

  saveVideoTime() {
    this.timeInterval = setInterval(() => {
      if (!this.video || !this.isShowVideo) {
        return;
      }

      this._videoHistory.set({
        reference: this._video.getReference(this.video.key),
        lastView: new Date().getTime(),
        currentTime: this.videoPlayer.nativeElement.currentTime
      });
      this.verifyAchievements();
    }, 300000);
  }

  private async getFavorites() {
    this.favorites$ = this._favorite.getAllByUser(this._auth.user.uid);
    this.favorites = await this.favorites$.pipe(take(1)).toPromise();
  }

  async addOrDropToFavorite(option: string, video) {
    let resp = await this._alert.favoriteAlert(option);
    if (!!resp && (resp) && option == 'add') {
      let favorite: any = {
        reference: this._video.getReference(video.key),
        title: video.title,
        trash: false,
      };

      let add;
      add = await this._favorite.add(this._auth.user.uid, favorite);

      favorite = {
        ...favorite,
        key: add.id,
        userKey: this._auth.user.uid
      };

      await this.favorites.push(favorite);
      await this._alert.presentToast('Se agregó a favoritos');
    }

    if (!!resp && (resp) && option == 'drop') {
      const index = this.favorites.findIndex((favorite) => favorite.reference.path == 'videos/' + video.key);
      await this._favorite.delete(this._auth.user.uid, this.favorites[index].key);
      await this.favorites.splice(index, 1);
      await this._alert.presentToast('Se eliminó de favoritos');
    }
  }

  isFavorite(videoKey) {
    let favorite = [];
    if (this.favorites?.length > 0) {
      favorite = this.favorites.filter(favorite => favorite.reference.path == 'videos/' + videoKey);
    }
    return favorite.length > 0;
  }

  gotoHome() {
    this.location.back();
  }

  loadRelatedVideos() {
    this.relatedVideosSubscription = this._video.getRelated(this.video.categories).subscribe(data => {
        this.relatedVideos = data;
      }
    );
  }

  async loadVideo(video: Video) {
    if (!!this.videoPlayer) {
      this._videoHistory.set({
        reference: this._video.getReference(this.video.key),
        lastView: new Date().getTime(),
        currentTime: this.videoPlayer.nativeElement.currentTime
      });
      this.verifyAchievements();
    }
    await this.presentLoading('Cargando...');
    this.video = video;
    setTimeout(() => {
      this.loadingCtrl.dismiss();
    }, 1000);
  }

  async presentLoading(message: string) {
    const loading = await this.loadingCtrl.create({
      message,
      spinner: 'circular'
    });
    return await loading.present();
  }

  async showVideo() {
    this.isShowVideo = true;
    const videoHistory = await this._videoHistory.get(this.video.key).pipe(take(1)).toPromise();

    if (!!videoHistory) {
      setTimeout(() => {
        this.videoPlayer.nativeElement.currentTime = videoHistory.currentTime;
      }, 1500);
    }

    this.videoPlayer.nativeElement.onplay = (() => {
      if (this.isInitialReproduction) {
        this._videoHistory.set({
          reference: this._video.getReference(this.video.key),
          lastView: new Date().getTime(),
          currentTime: 0
        });
        this.isInitialReproduction = false;
        this.verifyAchievements();
      }
    });
  }

  getBackground() {
    return `background: url(${this.video?.photoUrl}) no-repeat center center / cover`;
  }

  async openCommentsModal() {
    const modal = await this.modalController.create({
      component: CommentsComponent,
      componentProps: {
        video: this.video
      }
    });

    await modal.present();
  }

  async openUserList() {
    const modal = await this.modalController.create({
      component: UsersSeenCurrentVideoComponent,
      componentProps: {
        video: this.video
      }
    });

    await modal.present();
  }

  async handleOpenFriendsList() {
    const modal = await this.modalController.create({
      component: FriendsComponent,
      componentProps: {
        sharedVideo: this.video
      }
    });

    await modal.present();
  }

  handleShareInSocialMedia(social: string) {
    const title = `${this._user.user.firstName || ''} ${this._user.user.lastName || ''} ha terminado de ver el video ${this.video.title}`;
    const image = this.video.shareImageUrl || null;
    const url = `https://umana.com.mx`;

    switch (social) {
      case 'facebook':
        this.socialSharing.shareViaFacebook(title, image, url);
        break;

      case 'whatsapp':
        this.socialSharing.shareViaWhatsApp(title, image, url);
        break;

      case 'twitter':
        this.socialSharing.shareViaTwitter(title, image, url);
        break;

      case 'instagram':
        this.socialSharing.shareViaInstagram(title, image);
        break;
    }
  }

  async handleVideoOptions() {
    const actionSheet = await this.actionSheetController.create({
      buttons: [
        {
          text: 'Comentar',
          handler: async () => this.openCommentsModal()
        },
        {
          text: 'Compartir con un amigo',
          handler: async () => this.handleOpenFriendsList()
        }
      ]
    });
    await actionSheet.present();
  }
}
