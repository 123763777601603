import {Component, OnDestroy, OnInit} from '@angular/core';
import {ModalController, Platform} from '@ionic/angular';
import {User} from '../../../shared/interfaces/user';
import {Router} from '@angular/router';
import {InAppBrowser} from '@ionic-native/in-app-browser/ngx';
import {UserService} from '../../../shared/services/user.service';
import {Subscription} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {take} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';

@Component({
  selector: 'app-membership-expired',
  templateUrl: './membership-expired.component.html',
  styleUrls: ['./membership-expired.component.scss'],
})
export class MembershipExpiredComponent implements OnInit, OnDestroy {
  user: User = {key: ''} as User;
  endTime: number;
  userSubscribe: Subscription = new Subscription();
  isTesting: boolean;

  constructor(public modalController: ModalController,
              private iab: InAppBrowser,
              private router: Router,
              private _user: UserService,
              private http: HttpClient,
              private platform: Platform) {
  }

  ngOnInit() {
    this.getIsTesting();

    this.userSubscribe = this._user.getCurrentUser().subscribe(data => {
      this._user.user = data;
      if (data.membership != null) this.router.navigateByUrl(`user/videos`);
    });
  }

  ngOnDestroy() {
    this.userSubscribe.unsubscribe();
  }

  async getIsTesting() {
    const resp: any = await this.http.post(`${environment.apiBaseURL}/isTesting`, {
      version: environment.appVersion
    }).pipe(take(1)).toPromise();

    this.isTesting = resp.ok;
  }

  async goToProfile() {
    await this.router.navigateByUrl(`user/profile`);
    this.dismissModal();
  }

  dismissModal() {
    this.modalController.dismiss().then(r => r = null);
  }

  async goToRenovate() {
    if (this.platform.is('ios')){
      this.router.navigateByUrl('/user/memberships');
      this.dismissModal();
    }else{
      this.iab.create('https://pagos.umana.com.mx', '_blank');
    }
  }
}
