import { Injectable } from '@angular/core';
import { debounceTime, map, take } from 'rxjs/operators';
import { Video } from '../interfaces/video';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { FirebaseDataService } from '../template-services/firebase-data.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import firebase from 'firebase';
import { User } from '../interfaces/user';
import DocumentReference = firebase.firestore.DocumentReference;

@Injectable({
  providedIn: 'root'
})
export class VideoService {
  currentVideo: Video;
  videosArray: Video[] = [];
  query: any = {
    categories: [],
    duration: 'Todos',
    title: '',
    tools: [],
    difficulty: null
  };
  allVideos: Video[] = [];
  videos$: BehaviorSubject<Video[]> = new BehaviorSubject<Video[]>(this.allVideos);
  videosSubscription: Subscription = new Subscription();
  categorySelected: any;
  fetched: boolean = false;
  onSearchEmitter: Subject<boolean> = new Subject<boolean>();

  constructor(
    public db: FirebaseDataService,
    private afs: AngularFirestore
  ) {
  }

  async getAllWell() {
    if (this.videosArray.length == 0) {
      this.videosArray = await this.db.colWithIds$<Video>('videos', ref => ref
        .where('trash', '==', false)
        .orderBy('createdAt', 'desc'))
        .pipe(
          debounceTime(2000),
          take(1)
        ).toPromise();
    }
    return this.videosArray;
  }

  getAllCarousel() {
    return this.db.colWithIds$<Video>('videos', ref => ref
      .where('trash', '==', false)
      .where('isCarousel', '==', true)
      .orderBy('position', 'asc'));
  }

  getByBehaviorSubject(refresh: boolean = true) {
    if (refresh || !this.allVideos.length) {
      this.videosSubscription.unsubscribe();
      this.fetched = false;

      const query = this.query;

      this.videosSubscription = this.db.colWithIds$<Video>('videos', ref => this.getQuery(ref))
        .pipe(
          debounceTime(2000),
          map(videos => videos.filter(video => {
              if (query.tools.length > 0) {
                return (video.title || '').toLowerCase().includes(query.title.toLowerCase()) && (video.tools || []).some(tool => query.tools.some(queryTools => queryTools.key == tool.id));
              } else {
                return (video.title || '').toLowerCase().includes(query.title.toLowerCase());
              }
            })
          )
        ).subscribe(videos => {
          this.allVideos = videos;
          this.fetched = true;
          this.videos$.next(this.allVideos);
        });
    }

    return this.videos$;
  }

  getAll() {
    return this.db.colWithIds$<Video>('videos', ref => this.getQuery(ref))
      .pipe(
        debounceTime(2000),
        map(videos => videos.filter(video => {
            if (this.query.tools.length > 0) {
              return (video.title || '').toLowerCase().includes(this.query.title.toLowerCase()) && (video.tools || []).some(tool => this.query.tools.some(queryTools => queryTools.key == tool.id));
            } else {
              return (video.title || '').toLowerCase().includes(this.query.title.toLowerCase());
            }
          })
        )
      );
  }

  getLastFive(): Observable<Video[]> {
    return this.db.colWithIds$<Video>('videos', ref => ref
      .orderBy('createdAt', 'desc')
      .where('trash', '==', false).limit(5));
  }

  add(video: Video): void {
    this.afs.collection(`videos`).add(video);
  }

  set(videoKey: string, video: Video): void {
    this.afs.doc(`videos/${videoKey}`).set(video, { merge: true });
  }

  update(videoKey: string, video: Video): Promise<void> {
    return this.afs.doc(`videos/${videoKey}`).update(video);
  }

  get(videoKey: string): Observable<Video> {
    return this.db.doc$<Video>(`videos/${videoKey}`);
  }

  delete(videoKey: string): void {
    this.afs.doc(`videos/${videoKey}`).update({ trash: true });
  }

  getReference(videoKey: string): DocumentReference {
    return this.db.doc(`videos/${videoKey}`).ref;
  }

  getRelated(categories: DocumentReference[]): Observable<Video[]> {
    return this.db.colWithIds$<Video>('videos', ref => ref
      .where('trash', '==', false)
      .where('categories', 'array-contains-any', categories)
      .limit(3));
  }

  private getQuery(ref) {
    let query = ref
      .where('trash', '==', false);

    if (this.query && this.query.duration) {
      if (this.query.duration.includes('-')) {
        const rangeDuration = this.query.duration.split(' - ');

        query = query.where('duration', '>=', (+rangeDuration[0]) * 60);
        query = query.where('duration', '<=', (+rangeDuration[1]) * 60);
      } else if (this.query.duration.includes('>')) {
        const rangeDuration = this.query.duration.split(' >');

        query = query.where('duration', '>=', (+rangeDuration[0]) * 60);
      } else if (this.query.duration.includes('Todos')) {
        query = query.where('duration', '>=', 0);
      }
    }

    if (this.query.difficulty != null) {
      query = query.where('difficulty', '==', this.query.difficulty);
    }

    if (this.query.categories.length > 0) {
      query = query.where('categories', 'array-contains-any', this.query.categories.map(category => this.db.getReference(`categories/${category.key}`)));
    }

    return query;
  }

  getByCategory(category): Promise<any[]> {
    return this.db.colWithIds$('videos', ref => ref
      .where('trash', '==', false)
      .where('categories', 'array-contains', this.db.getReference(`categories/${category}`))).pipe(take(1)).toPromise();
  }

  getUserSeenByVideo(videoKey: string): Observable<User[]> {
    return this.db.colGroup<User>('videoHistory', ref => ref
      .where('reference', '==', this.db.getReference(`videos/${videoKey}`))).snapshotChanges().pipe(
      map(docs => docs.map(a => {
        const data = a.payload.doc.data();
        if (data == null) return data;
        data['key'] = a.payload.doc.id;
        data['user'] = this.db.getReference(`users/${a.payload.doc.ref.path.split('/')[1]}`);
        return data;
      })));
  }

  isEmptyQuery() {
    return this.query.categories.length == 0 && this.query.duration == 'Todos' && this.query.title == '' && this.query.tools.length == 0 && this.query.difficulty == null;
  }

}
