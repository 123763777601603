import {Component, OnDestroy, OnInit} from '@angular/core';
import {AchievementService} from '../../services/achievement.service';
import {UserAchievement} from '../../interfaces/achievement';
import {UserService} from '../../../shared/services/user.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-my-achievements',
  templateUrl: './my-achievements.component.html',
  styleUrls: ['./my-achievements.component.scss'],
})
export class MyAchievementsComponent implements OnInit, OnDestroy {
  myAchievements: UserAchievement[] = [];
  myAchievementsSubscription = new Subscription();

  constructor(private _achievements: AchievementService,
              private _user: UserService) {
  }

  ngOnInit() {
    this.myAchievementsSubscription = this._achievements.getMyAchievements(UserService.uid).subscribe(async achievements => {
      this.myAchievements = await this._achievements.populate(achievements.map(achievement => ({
        ...achievement,
        ref: this._achievements.getReference(`achievements/${achievement.key}`)
      })), ['ref']) as any;
    });
  }

  ngOnDestroy() {
    this.myAchievementsSubscription.unsubscribe();
  }
}
