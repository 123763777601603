import {Injectable} from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {Observable, ReplaySubject} from 'rxjs';
import {Video} from '../interfaces/video';
import {AuthService} from '../template-services/auth.service';
import {FirebaseDataService} from '../template-services/firebase-data.service';

@Injectable({
  providedIn: 'root'
})
export class VideoHistoryService {
  private fetched: boolean;
  private value$: ReplaySubject<any> = new ReplaySubject();

  constructor(private afs: AngularFirestore,
              private _auth: AuthService,
              private db: FirebaseDataService) {
  }

  getAll(): Observable<Video[]> {
    if (!this.fetched) {
      this.db.colWithIds$<Video>(`users/${this._auth.user.uid}/videoHistory`, ref => ref
        .orderBy('lastView', 'desc'))
        .subscribe(data => {
          this.fetched = true;
          this.value$.next(data);
        });
    }

    return this.value$;
  }

  async set(video: any): Promise<any> {
    return await this.afs.doc<any>(`users/${this._auth.user.uid}/videoHistory/${video.reference.id}`).set(video, {merge: true});
  }

  async delete(videoKey: string): Promise<void> {
    return await this.afs.doc<any>(`users/${this._auth.user.uid}/videoHistory/${videoKey}`).update({trash: true});
  }

  get(videoKey) {
    return this.afs.doc<any>(`users/${this._auth.user.uid}/videoHistory/${videoKey}`).valueChanges();
  }
}
