import { Injectable } from '@angular/core';
import { FirebaseDataService } from '../../shared/template-services/firebase-data.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { Calendar } from '../interfaces/calendar';
import { BehaviorSubject } from 'rxjs';
import { UserService } from '../../shared/services/user.service';
import { AlertService } from '../../shared/template-services/alert.service';

@Injectable({
  providedIn: 'root'
})
export class CalendarService extends FirebaseDataService {
  calendarSelected$: BehaviorSubject<Calendar> = new BehaviorSubject<Calendar>(null);
  feelingRankingSelected: string = null;

  constructor(private _db: AngularFirestore,
              private _alert: AlertService) {
    super(_db);
  }

  getAll() {
    return this.colWithIds$('calendars', ref => ref
      .where('trash', '==', false)
    );
  }

  async addFeeling(feelingFeedback: string, dayCalendarId: string) {
    if (await this._alert.confirm('¿Estás seguro de enviar tu estado?', '', 'Sí', 'No')) {
      await this.doc(`calendars/${dayCalendarId}/feelings/${UserService.uid}`).set({
        feelingRanking: this.feelingRankingSelected,
        feelingFeedback,
        createdAt: new Date().getTime(),
        user: this.getReference(`users/${UserService.uid}`)
      });

      this._alert.presentAlert('Gracias', 'Tu estado ha sido guardado');
    }
  }

  getMyFeeling(calendarId: string, userId: string) {
    return this.colWithIds$(`calendars/${calendarId}/feelings`, ref => ref
      .where('user', '==', this.getReference(`users/${userId}`))
    );
  }

  getAllMyFeeling(userId: string) {
    return this.colGroupWithIds$('feelings', ref => ref
      .where('user', '==', this.getReference(`users/${userId}`)
      ));
  }
}
