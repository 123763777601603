import {Component, OnInit} from '@angular/core';
import {AuthService} from '../../../shared/template-services/auth.service';
import {environment} from '../../../../environments/environment';
import {take} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';

@Component({
  selector: 'app-main-page-tabs',
  templateUrl: './main-page-tabs.component.html',
  styleUrls: ['./main-page-tabs.component.scss'],
})
export class MainPageTabsComponent implements OnInit {
  isUser: boolean;
  isTesting: boolean;

  constructor(private _auth: AuthService,
              private http: HttpClient) {
  }


  ngOnInit() {
    this.getIsTesting();

    this._auth.$isUser.subscribe(isUser => {
      this.isUser = isUser;
    });
  }

  async getIsTesting() {
    const resp: any = await this.http.post(`${environment.apiBaseURL}/isTesting`, {
      version: environment.appVersion
    }).pipe(take(1)).toPromise();

    this.isTesting = resp.ok;
  }
}
