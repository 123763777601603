import {Component, OnInit} from '@angular/core';
import {AlertService} from 'src/app/shared/template-services/alert.service';
import {AuthService} from 'src/app/shared/template-services/auth.service';
import {MembershipService} from '../../../shared/services/membership.service';
import {ModalController} from '@ionic/angular';

@Component({
  selector: 'app-cancellation-opinion',
  templateUrl: './cancellation-opinion.component.html',
  styleUrls: ['./cancellation-opinion.component.css']
})
export class CancellationOpinionComponent implements OnInit {
  options = [
    'Me cuesta hacer ejercicio en mi casa.',
    'Las clases no son lo que pensaba.',
    'Volveré después.',
    'Otra',
  ];
  optionSelected = null;
  customOption = null;

  constructor(private _membership: MembershipService,
              private _alert: AlertService,
              private _auth: AuthService,
              private modalController: ModalController) {
  }

  ngOnInit(): void {
  }

  submit() {
    if (!this.optionSelected) return this._alert.presentToast('Favor de seleccionar una opción');
    if (!!this.customOption) this.customOption = this.customOption.trim();
    if (this.optionSelected == 'Otra' && !this.customOption) return this._alert.presentToast('Favor de escribir el motivo de tu cancelación');

    this._membership.addCancellationOpinion(this.optionSelected, this.customOption, this._auth.user.uid);

    this.modalController.dismiss()
  }

}
