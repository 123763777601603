import { Pipe, PipeTransform } from '@angular/core';
import {FirebaseDataService} from '../template-services/firebase-data.service';
import {DocumentReference} from '@angular/fire/firestore';
import {Observable} from 'rxjs';

@Pipe({
  name: 'doc'
})
export class DocPipe implements PipeTransform {

  constructor(private db: FirebaseDataService) {
  }

  transform(reference: DocumentReference): Observable<any> {
    return this.db.doc$(reference.path);
  }

}
