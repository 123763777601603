import { Injectable } from '@angular/core';
import {FirebaseDataService} from "../../shared/template-services/firebase-data.service";
import {AngularFirestore} from "@angular/fire/firestore";
import {Observable} from "rxjs";
import {Coupon} from '../../admin/interfaces/coupon';

@Injectable({
  providedIn: 'root'
})
export class CouponService {

  constructor(private db: FirebaseDataService,
              private afs: AngularFirestore) {

  }

  getAll(): Observable<Coupon[]> {
    return this.db.colWithIds$<Coupon>('coupons', ref => ref
      .where('trash', '==', false));
  }

  add(coupon: Coupon): void {
    this.afs.collection(`coupons`).add(coupon);
  }

  update(couponKey: string, coupon: Coupon): Promise<void> {
    return this.afs.doc(`coupons/${couponKey}`).update(coupon);
  }

  get(couponKey: string): Observable<Coupon> {
    return this.db.doc$<Coupon>(`coupons/${couponKey}`);
  }

  delete(couponKey: string): void {
    this.afs.doc(`coupons/${couponKey}`).update({trash: true});
  }

  reactivate(couponKey: string): void {
    this.afs.doc(`coupons/${couponKey}`).update({lastUpdate: (new Date()).getTime()});
  }
}
