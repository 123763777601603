import {Injectable} from '@angular/core';
import {FirebaseDataService} from '../../shared/template-services/firebase-data.service';
import {Observable} from 'rxjs';
import {IRecipe} from '../interfaces/IRecipe';
import {take} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RecipeService {

  constructor(private db: FirebaseDataService) {
  }

  getAll(): Observable<IRecipe[]> {
    return this.db.colWithIds$('recipes', ref => ref.where('trash', '==', false));
  }

  get(id: string): Promise<any> {
    return this.db.docWithId$(`recipes-v2/${id}`).pipe(take(1)).toPromise();
  }

  getAllV2(): Observable<IRecipe[]> {
    return this.db.colWithIds$('recipes-v2', ref => ref.where('trash', '==', false));
  }

  getCategories(): Observable<any[]> {
    return this.db.colWithIds$('recipesCategories', ref => ref.where('trash', '==', false));
  }
}
